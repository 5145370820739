.App {
  text-align: center;
}
.auth-main:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: url(../Images/Endless-Constellation.svg)  top left fixed;
}

.footer{
  margin-top: -41px;
  position: fixed;
  background: #504df7;
  padding-top: 8px;
  color: white;
  font-size: 16px;
  width: 100%;
  padding-bottom: 10px;
  font-weight: 600;
  font-style: italic;
}

a{
  text-decoration: none !important;
}

.logout_btn{
  cursor: pointer;
}

.dash_logo{
  width: 40px;
  border-radius: 40px;
  margin-right: 5%;
}

.dash_text{
  font-weight: 650;
}

.line-on-side {
  border-bottom: 1px solid #000 !important;
  line-height: .1em !important;
}

.line-on-side span {
  background: #fff;
  padding: 0 15px;
  font-size: 18px;
  font-weight: 600;
}

.footer_link{
  color: #fff;
}

.eye-icon{
  position: relative;
  top: -33px;
  float: right;
  right: 10px;
  cursor: pointer;
}

.page_layout{
  width: 100%;
}

.location_mark{
  font-size: 25px;
  margin-top: 13px;
}

.location_text{
  font-size: 25px;
  font-weight: 550;
}

.cursor-pointer{
  cursor: pointer;
}

#color1{
  background: #FE9568;
}

#color2{
  background: #0AC383;
}
#color3{
  background: #01ACAF;
}

.label {
  padding: 7px 10px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 85%;
}

.rounded {
  border-radius: 0.25rem !important;
}

.custom_footer{
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  color: white;
  text-align: center;
  background-color: #337A90;
  padding: 5px;
}

.edit_btn{
  color: #fff !important;
  background-image: linear-gradient(230deg, #009846, #009846);
}
.delete_btn{
  color: #fff !important;
  background-image: linear-gradient(230deg, #d63722, #d63722);
}

tr.odd{
  background-color: #e6f5fa !important;
}

.colorLine{
  border-top: 1px solid black;
}

.pnotify-text-with-max-height {
  overflow-y: hidden !important;
}

.error_msg{
  color: red;
  font-size: 80%;
}

.custom-card-header{
  background: #38c5f1b5 !important;
  color: #000 !important;
}

.custom-card-body{
  background: #F5F5F5 !important;
}

.train_info_style{
  font-size: 18px;
  font-weight: 600;
}

.custom-table-head{
  background: #fff;
}

text.highcharts-credits {
  display: none !important;
}

.table-custom-scrollbar{
  height: 500px;
  overflow-y: scroll;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eaf4fd !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #eaf4fd !important;
}

/* Cusrom Scroll Bar width */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.react-date-picker__wrapper {
    border: 0px solid gray !important;
}

.custum-date{
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-label{
  font-weight: 400;
  color: #6c757d;
  font-size: 15px;
}

.config-table th{
  padding: 10px;
  vertical-align: middle !important;
}

.config-table td{
  padding: 10px;
}

.input_width{
  width: 100%;
}

.alert-table-style{
  height: 170px;
  overflow-y: scroll;
}

@media screen and (max-width: 767px){
  .custom_nav>li {
    display: table-cell;
    position: inherit;
    text-align: right;
    margin-top: 10px;
  }
}