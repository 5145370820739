@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&amp;display=swap");

@font-face {
    font-family: 'simple-Line-Icons';
    src: url("https://wrraptheme.com/templates/iconic/fonts/simple-Line-Icons.eot");
    src: url("https://wrraptheme.com/templates/iconic/fonts/simple-Line-Icons.eot") format("embedded-opentype"), url("https://wrraptheme.com/templates/iconic/fonts/simple-Line-Icons.woff") format("woff"), url("https://wrraptheme.com/templates/iconic/fonts/simple-Line-Icons.ttf") format("truetype"), url("https://wrraptheme.com/templates/iconic/fonts/simple-Line-Icons.svg#Simple-Line-Icons") format("svg");
    font-weight: normal;
    font-style: normal
}

[data-icon]:before {
    font-family: 'simple-Line-Icons';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 17px
}

.icon-user-female,
.icon-user-follow,
.icon-user-following,
.icon-user-unfollow,
.icon-trophy,
.icon-screen-smartphone,
.icon-screen-desktop,
.icon-plane,
.icon-notebook,
.icon-moustache,
.icon-mouse,
.icon-magnet,
.icon-energy,
.icon-emoticon-smile,
.icon-disc,
.icon-cursor-move,
.icon-crop,
.icon-credit-card,
.icon-chemistry,
.icon-user,
.icon-speedometer,
.icon-social-youtube,
.icon-social-twitter,
.icon-social-tumblr,
.icon-social-facebook,
.icon-social-dropbox,
.icon-social-dribbble,
.icon-shield,
.icon-screen-tablet,
.icon-magic-wand,
.icon-hourglass,
.icon-graduation,
.icon-ghost,
.icon-game-controller,
.icon-fire,
.icon-eyeglasses,
.icon-envelope-open,
.icon-envelope-letter,
.icon-bell,
.icon-badge,
.icon-anchor,
.icon-wallet,
.icon-vector,
.icon-speech,
.icon-puzzle,
.icon-printer,
.icon-present,
.icon-playlist,
.icon-pin,
.icon-picture,
.icon-map,
.icon-layers,
.icon-handbag,
.icon-globe-alt,
.icon-globe,
.icon-frame,
.icon-folder-alt,
.icon-film,
.icon-feed,
.icon-earphones-alt,
.icon-earphones,
.icon-drop,
.icon-drawer,
.icon-docs,
.icon-directions,
.icon-direction,
.icon-diamond,
.icon-cup,
.icon-compass,
.icon-call-out,
.icon-call-in,
.icon-call-end,
.icon-calculator,
.icon-bubbles,
.icon-briefcase,
.icon-book-open,
.icon-basket-loaded,
.icon-basket,
.icon-bag,
.icon-action-undo,
.icon-action-redo,
.icon-wrench,
.icon-umbrella,
.icon-trash,
.icon-tag,
.icon-support,
.icon-size-fullscreen,
.icon-size-actual,
.icon-shuffle,
.icon-share-alt,
.icon-share,
.icon-rocket,
.icon-question,
.icon-pie-chart,
.icon-pencil,
.icon-note,
.icon-music-tone-alt,
.icon-music-tone,
.icon-microphone,
.icon-loop,
.icon-logout,
.icon-login,
.icon-list,
.icon-like,
.icon-home,
.icon-grid,
.icon-graph,
.icon-equalizer,
.icon-dislike,
.icon-cursor,
.icon-control-start,
.icon-control-rewind,
.icon-control-play,
.icon-control-pause,
.icon-control-forward,
.icon-control-end,
.icon-calendar,
.icon-bulb,
.icon-bar-chart,
.icon-arrow-up,
.icon-arrow-right,
.icon-arrow-left,
.icon-arrow-down,
.icon-ban,
.icon-bubble,
.icon-camcorder,
.icon-camera,
.icon-check,
.icon-clock,
.icon-close,
.icon-cloud-download,
.icon-cloud-upload,
.icon-doc,
.icon-envelope,
.icon-eye,
.icon-flag,
.icon-folder,
.icon-heart,
.icon-info,
.icon-key,
.icon-link,
.icon-lock,
.icon-lock-open,
.icon-magnifier,
.icon-magnifier-add,
.icon-magnifier-remove,
.icon-paper-clip,
.icon-paper-plane,
.icon-plus,
.icon-pointer,
.icon-power,
.icon-refresh,
.icon-reload,
.icon-settings,
.icon-star,
.icon-symbol-female,
.icon-symbol-male,
.icon-target,
.icon-volume-1,
.icon-volume-2,
.icon-volume-off,
.icon-users {
    font-family: 'Simple-Line-Icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased
}

.icon-user-female:before {
    content: "\e000"
}

.icon-user-follow:before {
    content: "\e002"
}

.icon-user-following:before {
    content: "\e003"
}

.icon-user-unfollow:before {
    content: "\e004"
}

.icon-trophy:before {
    content: "\e006"
}

.icon-screen-smartphone:before {
    content: "\e010"
}

.icon-screen-desktop:before {
    content: "\e011"
}

.icon-plane:before {
    content: "\e012"
}

.icon-notebook:before {
    content: "\e013"
}

.icon-moustache:before {
    content: "\e014"
}

.icon-mouse:before {
    content: "\e015"
}

.icon-magnet:before {
    content: "\e016"
}

.icon-energy:before {
    content: "\e020"
}

.icon-emoticon-smile:before {
    content: "\e021"
}

.icon-disc:before {
    content: "\e022"
}

.icon-cursor-move:before {
    content: "\e023"
}

.icon-crop:before {
    content: "\e024"
}

.icon-credit-card:before {
    content: "\e025"
}

.icon-chemistry:before {
    content: "\e026"
}

.icon-user:before {
    content: "\e005"
}

.icon-speedometer:before {
    content: "\e007"
}

.icon-social-youtube:before {
    content: "\e008"
}

.icon-social-twitter:before {
    content: "\e009"
}

.icon-social-tumblr:before {
    content: "\e00a"
}

.icon-social-facebook:before {
    content: "\e00b"
}

.icon-social-dropbox:before {
    content: "\e00c"
}

.icon-social-dribbble:before {
    content: "\e00d"
}

.icon-shield:before {
    content: "\e00e"
}

.icon-screen-tablet:before {
    content: "\e00f"
}

.icon-magic-wand:before {
    content: "\e017"
}

.icon-hourglass:before {
    content: "\e018"
}

.icon-graduation:before {
    content: "\e019"
}

.icon-ghost:before {
    content: "\e01a"
}

.icon-game-controller:before {
    content: "\e01b"
}

.icon-fire:before {
    content: "\e01c"
}

.icon-eyeglasses:before {
    content: "\e01d"
}

.icon-envelope-open:before {
    content: "\e01e"
}

.icon-envelope-letter:before {
    content: "\e01f"
}

.icon-bell:before {
    content: "\e027"
}

.icon-badge:before {
    content: "\e028"
}

.icon-anchor:before {
    content: "\e029"
}

.icon-wallet:before {
    content: "\e02a"
}

.icon-vector:before {
    content: "\e02b"
}

.icon-speech:before {
    content: "\e02c"
}

.icon-puzzle:before {
    content: "\e02d"
}

.icon-printer:before {
    content: "\e02e"
}

.icon-present:before {
    content: "\e02f"
}

.icon-playlist:before {
    content: "\e030"
}

.icon-pin:before {
    content: "\e031"
}

.icon-picture:before {
    content: "\e032"
}

.icon-map:before {
    content: "\e033"
}

.icon-layers:before {
    content: "\e034"
}

.icon-handbag:before {
    content: "\e035"
}

.icon-globe-alt:before {
    content: "\e036"
}

.icon-globe:before {
    content: "\e037"
}

.icon-frame:before {
    content: "\e038"
}

.icon-folder-alt:before {
    content: "\e039"
}

.icon-film:before {
    content: "\e03a"
}

.icon-feed:before {
    content: "\e03b"
}

.icon-earphones-alt:before {
    content: "\e03c"
}

.icon-earphones:before {
    content: "\e03d"
}

.icon-drop:before {
    content: "\e03e"
}

.icon-drawer:before {
    content: "\e03f"
}

.icon-docs:before {
    content: "\e040"
}

.icon-directions:before {
    content: "\e041"
}

.icon-direction:before {
    content: "\e042"
}

.icon-diamond:before {
    content: "\e043"
}

.icon-cup:before {
    content: "\e044"
}

.icon-compass:before {
    content: "\e045"
}

.icon-call-out:before {
    content: "\e046"
}

.icon-call-in:before {
    content: "\e047"
}

.icon-call-end:before {
    content: "\e048"
}

.icon-calculator:before {
    content: "\e049"
}

.icon-bubbles:before {
    content: "\e04a"
}

.icon-briefcase:before {
    content: "\e04b"
}

.icon-book-open:before {
    content: "\e04c"
}

.icon-basket-loaded:before {
    content: "\e04d"
}

.icon-basket:before {
    content: "\e04e"
}

.icon-bag:before {
    content: "\e04f"
}

.icon-action-undo:before {
    content: "\e050"
}

.icon-action-redo:before {
    content: "\e051"
}

.icon-wrench:before {
    content: "\e052"
}

.icon-umbrella:before {
    content: "\e053"
}

.icon-trash:before {
    content: "\e054"
}

.icon-tag:before {
    content: "\e055"
}

.icon-support:before {
    content: "\e056"
}

.icon-size-fullscreen:before {
    content: "\e057"
}

.icon-size-actual:before {
    content: "\e058"
}

.icon-shuffle:before {
    content: "\e059"
}

.icon-share-alt:before {
    content: "\e05a"
}

.icon-share:before {
    content: "\e05b"
}

.icon-rocket:before {
    content: "\e05c"
}

.icon-question:before {
    content: "\e05d"
}

.icon-pie-chart:before {
    content: "\e05e"
}

.icon-pencil:before {
    content: "\e05f"
}

.icon-note:before {
    content: "\e060"
}

.icon-music-tone-alt:before {
    content: "\e061"
}

.icon-music-tone:before {
    content: "\e062"
}

.icon-microphone:before {
    content: "\e063"
}

.icon-loop:before {
    content: "\e064"
}

.icon-logout:before {
    content: "\e065"
}

.icon-login:before {
    content: "\e066"
}

.icon-list:before {
    content: "\e067"
}

.icon-like:before {
    content: "\e068"
}

.icon-home:before {
    content: "\e069"
}

.icon-grid:before {
    content: "\e06a"
}

.icon-graph:before {
    content: "\e06b"
}

.icon-equalizer:before {
    content: "\e06c"
}

.icon-dislike:before {
    content: "\e06d"
}

.icon-cursor:before {
    content: "\e06e"
}

.icon-control-start:before {
    content: "\e06f"
}

.icon-control-rewind:before {
    content: "\e070"
}

.icon-control-play:before {
    content: "\e071"
}

.icon-control-pause:before {
    content: "\e072"
}

.icon-control-forward:before {
    content: "\e073"
}

.icon-control-end:before {
    content: "\e074"
}

.icon-calendar:before {
    content: "\e075"
}

.icon-bulb:before {
    content: "\e076"
}

.icon-bar-chart:before {
    content: "\e077"
}

.icon-arrow-up:before {
    content: "\e078"
}

.icon-arrow-right:before {
    content: "\e079"
}

.icon-arrow-left:before {
    content: "\e07a"
}

.icon-arrow-down:before {
    content: "\e07b"
}

.icon-ban:before {
    content: "\e07c"
}

.icon-bubble:before {
    content: "\e07d"
}

.icon-camcorder:before {
    content: "\e07e"
}

.icon-camera:before {
    content: "\e07f"
}

.icon-check:before {
    content: "\e080"
}

.icon-clock:before {
    content: "\e081"
}

.icon-close:before {
    content: "\e082"
}

.icon-cloud-download:before {
    content: "\e083"
}

.icon-cloud-upload:before {
    content: "\e084"
}

.icon-doc:before {
    content: "\e085"
}

.icon-envelope:before {
    content: "\e086"
}

.icon-eye:before {
    content: "\e087"
}

.icon-flag:before {
    content: "\e088"
}

.icon-folder:before {
    content: "\e089"
}

.icon-heart:before {
    content: "\e08a"
}

.icon-info:before {
    content: "\e08b"
}

.icon-key:before {
    content: "\e08c"
}

.icon-link:before {
    content: "\e08d"
}

.icon-lock:before {
    content: "\e08e"
}

.icon-lock-open:before {
    content: "\e08f"
}

.icon-magnifier:before {
    content: "\e090"
}

.icon-magnifier-add:before {
    content: "\e091"
}

.icon-magnifier-remove:before {
    content: "\e092"
}

.icon-paper-clip:before {
    content: "\e093"
}

.icon-paper-plane:before {
    content: "\e094"
}

.icon-plus:before {
    content: "\e095"
}

.icon-pointer:before {
    content: "\e096"
}

.icon-power:before {
    content: "\e097"
}

.icon-refresh:before {
    content: "\e098"
}

.icon-reload:before {
    content: "\e099"
}

.icon-settings:before {
    content: "\e09a"
}

.icon-star:before {
    content: "\e09b"
}

.icon-symbol-female:before {
    content: "\e09c"
}

.icon-symbol-male:before {
    content: "\e09d"
}

.icon-target:before {
    content: "\e09e"
}

.icon-volume-1:before {
    content: "\e09f"
}

.icon-volume-2:before {
    content: "\e0a0"
}

.icon-volume-off:before {
    content: "\e0a1"
}

.icon-users:before {
    content: "\e001"
}

@font-face {
    font-family: 'weathericons';
    src: url("https://wrraptheme.com/templates/iconic/fonts/weathericons-regular-webfont.eot");
    src: url("https://wrraptheme.com/templates/iconic/fonts/weathericons-regular-webfont.eot?#iefix") format("embedded-opentype"), url("https://wrraptheme.com/templates/iconic/fonts/weathericons-regular-webfont.woff") format("woff"), url("https://wrraptheme.com/templates/iconic/fonts/weathericons-regular-webfont.ttf") format("truetype"), url("https://wrraptheme.com/templates/iconic/fonts/weathericons-regular-webfont.svg#weathericons-regular-webfontRg") format("svg");
    font-weight: normal;
    font-style: normal
}

.wi {
    display: inline-block;
    font-family: 'weathericons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.wi-day-cloudy-gusts:before {
    content: "\f000"
}

.wi-day-cloudy-windy:before {
    content: "\f001"
}

.wi-day-cloudy:before {
    content: "\f002"
}

.wi-day-fog:before {
    content: "\f003"
}

.wi-day-hail:before {
    content: "\f004"
}

.wi-day-lightning:before {
    content: "\f005"
}

.wi-day-rain-mix:before {
    content: "\f006"
}

.wi-day-rain-wind:before {
    content: "\f007"
}

.wi-day-rain:before {
    content: "\f008"
}

.wi-day-showers:before {
    content: "\f009"
}

.wi-day-snow:before {
    content: "\f00a"
}

.wi-day-sprinkle:before {
    content: "\f00b"
}

.wi-day-sunny-overcast:before {
    content: "\f00c"
}

.wi-day-sunny:before {
    content: "\f00d"
}

.wi-day-storm-showers:before {
    content: "\f00e"
}

.wi-day-thunderstorm:before {
    content: "\f010"
}

.wi-cloudy-gusts:before {
    content: "\f011"
}

.wi-cloudy-windy:before {
    content: "\f012"
}

.wi-cloudy:before {
    content: "\f013"
}

.wi-fog:before {
    content: "\f014"
}

.wi-hail:before {
    content: "\f015"
}

.wi-lightning:before {
    content: "\f016"
}

.wi-rain-mix:before {
    content: "\f017"
}

.wi-rain-wind:before {
    content: "\f018"
}

.wi-rain:before {
    content: "\f019"
}

.wi-showers:before {
    content: "\f01a"
}

.wi-snow:before {
    content: "\f01b"
}

.wi-sprinkle:before {
    content: "\f01c"
}

.wi-storm-showers:before {
    content: "\f01d"
}

.wi-thunderstorm:before {
    content: "\f01e"
}

.wi-windy:before {
    content: "\f021"
}

.wi-night-alt-cloudy-gusts:before {
    content: "\f022"
}

.wi-night-alt-cloudy-windy:before {
    content: "\f023"
}

.wi-night-alt-hail:before {
    content: "\f024"
}

.wi-night-alt-lightning:before {
    content: "\f025"
}

.wi-night-alt-rain-mix:before {
    content: "\f026"
}

.wi-night-alt-rain-wind:before {
    content: "\f027"
}

.wi-night-alt-rain:before {
    content: "\f028"
}

.wi-night-alt-showers:before {
    content: "\f029"
}

.wi-night-alt-snow:before {
    content: "\f02a"
}

.wi-night-alt-sprinkle:before {
    content: "\f02b"
}

.wi-night-alt-storm-showers:before {
    content: "\f02c"
}

.wi-night-alt-thunderstorm:before {
    content: "\f02d"
}

.wi-night-clear:before {
    content: "\f02e"
}

.wi-night-cloudy-gusts:before {
    content: "\f02f"
}

.wi-night-cloudy-windy:before {
    content: "\f030"
}

.wi-night-cloudy:before {
    content: "\f031"
}

.wi-night-hail:before {
    content: "\f032"
}

.wi-night-lightning:before {
    content: "\f033"
}

.wi-night-rain-mix:before {
    content: "\f034"
}

.wi-night-rain-wind:before {
    content: "\f035"
}

.wi-night-rain:before {
    content: "\f036"
}

.wi-night-showers:before {
    content: "\f037"
}

.wi-night-snow:before {
    content: "\f038"
}

.wi-night-sprinkle:before {
    content: "\f039"
}

.wi-night-storm-showers:before {
    content: "\f03a"
}

.wi-night-thunderstorm:before {
    content: "\f03b"
}

.wi-celsius:before {
    content: "\f03c"
}

.wi-cloud-down:before {
    content: "\f03d"
}

.wi-cloud-refresh:before {
    content: "\f03e"
}

.wi-cloud-up:before {
    content: "\f040"
}

.wi-cloud:before {
    content: "\f041"
}

.wi-degrees:before {
    content: "\f042"
}

.wi-down-left:before {
    content: "\f043"
}

.wi-down:before {
    content: "\f044"
}

.wi-fahrenheit:before {
    content: "\f045"
}

.wi-horizon-alt:before {
    content: "\f046"
}

.wi-horizon:before {
    content: "\f047"
}

.wi-left:before {
    content: "\f048"
}

.wi-lightning:before {
    content: "\f016"
}

.wi-night-fog:before {
    content: "\f04a"
}

.wi-refresh-alt:before {
    content: "\f04b"
}

.wi-refresh:before {
    content: "\f04c"
}

.wi-right:before {
    content: "\f04d"
}

.wi-sprinkles:before {
    content: "\f04e"
}

.wi-strong-wind:before {
    content: "\f050"
}

.wi-sunrise:before {
    content: "\f051"
}

.wi-sunset:before {
    content: "\f052"
}

.wi-thermometer-exterior:before {
    content: "\f053"
}

.wi-thermometer-internal:before {
    content: "\f054"
}

.wi-thermometer:before {
    content: "\f055"
}

.wi-tornado:before {
    content: "\f056"
}

.wi-up-right:before {
    content: "\f057"
}

.wi-up:before {
    content: "\f058"
}

.wi-wind-west:before {
    content: "\f059"
}

.wi-wind-south-west:before {
    content: "\f05a"
}

.wi-wind-south-east:before {
    content: "\f05b"
}

.wi-wind-south:before {
    content: "\f05c"
}

.wi-wind-north-west:before {
    content: "\f05d"
}

.wi-wind-north-east:before {
    content: "\f05e"
}

.wi-wind-north:before {
    content: "\f060"
}

.wi-wind-east:before {
    content: "\f061"
}

.wi-smoke:before {
    content: "\f062"
}

.wi-dust:before {
    content: "\f063"
}

.wi-snow-wind:before {
    content: "\f064"
}

.wi-day-snow-wind:before {
    content: "\f065"
}

.wi-night-snow-wind:before {
    content: "\f066"
}

.wi-night-alt-snow-wind:before {
    content: "\f067"
}

.wi-day-sleet-storm:before {
    content: "\f068"
}

.wi-night-sleet-storm:before {
    content: "\f069"
}

.wi-night-alt-sleet-storm:before {
    content: "\f06a"
}

.wi-day-snow-thunderstorm:before {
    content: "\f06b"
}

.wi-night-snow-thunderstorm:before {
    content: "\f06c"
}

.wi-night-alt-snow-thunderstorm:before {
    content: "\f06d"
}

.wi-solar-eclipse:before {
    content: "\f06e"
}

.wi-lunar-eclipse:before {
    content: "\f070"
}

.wi-meteor:before {
    content: "\f071"
}

.wi-hot:before {
    content: "\f072"
}

.wi-hurricane:before {
    content: "\f073"
}

.wi-smog:before {
    content: "\f074"
}

.wi-alien:before {
    content: "\f075"
}

.wi-snowflake-cold:before {
    content: "\f076"
}

.wi-stars:before {
    content: "\f077"
}

.wi-night-partly-cloudy:before {
    content: "\f083"
}

.wi-umbrella:before {
    content: "\f084"
}

.wi-day-windy:before {
    content: "\f085"
}

.wi-night-alt-cloudy:before {
    content: "\f086"
}

.wi-up-left:before {
    content: "\f087"
}

.wi-down-right:before {
    content: "\f088"
}

.wi-time-12:before {
    content: "\f089"
}

.wi-time-1:before {
    content: "\f08a"
}

.wi-time-2:before {
    content: "\f08b"
}

.wi-time-3:before {
    content: "\f08c"
}

.wi-time-4:before {
    content: "\f08d"
}

.wi-time-5:before {
    content: "\f08e"
}

.wi-time-6:before {
    content: "\f08f"
}

.wi-time-7:before {
    content: "\f090"
}

.wi-time-8:before {
    content: "\f091"
}

.wi-time-9:before {
    content: "\f092"
}

.wi-time-10:before {
    content: "\f093"
}

.wi-time-11:before {
    content: "\f094"
}

.wi-day-sleet:before {
    content: "\f0b2"
}

.wi-night-sleet:before {
    content: "\f0b3"
}

.wi-night-alt-sleet:before {
    content: "\f0b4"
}

.wi-sleet:before {
    content: "\f0b5"
}

.wi-day-haze:before {
    content: "\f0b6"
}

.wi-beafort-0:before {
    content: "\f0b7"
}

.wi-beafort-1:before {
    content: "\f0b8"
}

.wi-beafort-2:before {
    content: "\f0b9"
}

.wi-beafort-3:before {
    content: "\f0ba"
}

.wi-beafort-4:before {
    content: "\f0bb"
}

.wi-beafort-5:before {
    content: "\f0bc"
}

.wi-beafort-6:before {
    content: "\f0bd"
}

.wi-beafort-7:before {
    content: "\f0be"
}

.wi-beafort-8:before {
    content: "\f0bf"
}

.wi-beafort-9:before {
    content: "\f0c0"
}

.wi-beafort-10:before {
    content: "\f0c1"
}

.wi-beafort-11:before {
    content: "\f0c2"
}

.wi-beafort-12:before {
    content: "\f0c3"
}

.wi-wind-default:before {
    content: "\f0b1"
}

.wi-wind-default._0-deg {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
}

.wi-wind-default._15-deg {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg)
}

.wi-wind-default._30-deg {
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg)
}

.wi-wind-default._45-deg {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.wi-wind-default._60-deg {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg)
}

.wi-wind-default._75-deg {
    -webkit-transform: rotate(75deg);
    -moz-transform: rotate(75deg);
    -ms-transform: rotate(75deg);
    -o-transform: rotate(75deg);
    transform: rotate(75deg)
}

.wi-wind-default._90-deg {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

.wi-wind-default._105-deg {
    -webkit-transform: rotate(105deg);
    -moz-transform: rotate(105deg);
    -ms-transform: rotate(105deg);
    -o-transform: rotate(105deg);
    transform: rotate(105deg)
}

.wi-wind-default._120-deg {
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg)
}

.wi-wind-default._135-deg {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg)
}

.wi-wind-default._150-deg {
    -webkit-transform: rotate(150deg);
    -moz-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    -o-transform: rotate(150deg);
    transform: rotate(150deg)
}

.wi-wind-default._165-deg {
    -webkit-transform: rotate(165deg);
    -moz-transform: rotate(165deg);
    -ms-transform: rotate(165deg);
    -o-transform: rotate(165deg);
    transform: rotate(165deg)
}

.wi-wind-default._180-deg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
}

.wi-wind-default._195-deg {
    -webkit-transform: rotate(195deg);
    -moz-transform: rotate(195deg);
    -ms-transform: rotate(195deg);
    -o-transform: rotate(195deg);
    transform: rotate(195deg)
}

.wi-wind-default._210-deg {
    -webkit-transform: rotate(210deg);
    -moz-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    -o-transform: rotate(210deg);
    transform: rotate(210deg)
}

.wi-wind-default._225-deg {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg)
}

.wi-wind-default._240-deg {
    -webkit-transform: rotate(240deg);
    -moz-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    -o-transform: rotate(240deg);
    transform: rotate(240deg)
}

.wi-wind-default._255-deg {
    -webkit-transform: rotate(255deg);
    -moz-transform: rotate(255deg);
    -ms-transform: rotate(255deg);
    -o-transform: rotate(255deg);
    transform: rotate(255deg)
}

.wi-wind-default._270-deg {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg)
}

.wi-wind-default._285-deg {
    -webkit-transform: rotate(295deg);
    -moz-transform: rotate(295deg);
    -ms-transform: rotate(295deg);
    -o-transform: rotate(295deg);
    transform: rotate(295deg)
}

.wi-wind-default._300-deg {
    -webkit-transform: rotate(300deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    transform: rotate(300deg)
}

.wi-wind-default._315-deg {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg)
}

.wi-wind-default._330-deg {
    -webkit-transform: rotate(330deg);
    -moz-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    -o-transform: rotate(330deg);
    transform: rotate(330deg)
}

.wi-wind-default._345-deg {
    -webkit-transform: rotate(345deg);
    -moz-transform: rotate(345deg);
    -ms-transform: rotate(345deg);
    -o-transform: rotate(345deg);
    transform: rotate(345deg)
}

.wi-moon-new:before {
    content: "\f095"
}

.wi-moon-waxing-cresent-1:before {
    content: "\f096"
}

.wi-moon-waxing-cresent-2:before {
    content: "\f097"
}

.wi-moon-waxing-cresent-3:before {
    content: "\f098"
}

.wi-moon-waxing-cresent-4:before {
    content: "\f099"
}

.wi-moon-waxing-cresent-5:before {
    content: "\f09a"
}

.wi-moon-waxing-cresent-6:before {
    content: "\f09b"
}

.wi-moon-first-quarter:before {
    content: "\f09c"
}

.wi-moon-waxing-gibbous-1:before {
    content: "\f09d"
}

.wi-moon-waxing-gibbous-2:before {
    content: "\f09e"
}

.wi-moon-waxing-gibbous-3:before {
    content: "\f09f"
}

.wi-moon-waxing-gibbous-4:before {
    content: "\f0a0"
}

.wi-moon-waxing-gibbous-5:before {
    content: "\f0a1"
}

.wi-moon-waxing-gibbous-6:before {
    content: "\f0a2"
}

.wi-moon-full:before {
    content: "\f0a3"
}

.wi-moon-waning-gibbous-1:before {
    content: "\f0a4"
}

.wi-moon-waning-gibbous-2:before {
    content: "\f0a5"
}

.wi-moon-waning-gibbous-3:before {
    content: "\f0a6"
}

.wi-moon-waning-gibbous-4:before {
    content: "\f0a7"
}

.wi-moon-waning-gibbous-5:before {
    content: "\f0a8"
}

.wi-moon-waning-gibbous-6:before {
    content: "\f0a9"
}

.wi-moon-3rd-quarter:before {
    content: "\f0aa"
}

.wi-moon-waning-crescent-1:before {
    content: "\f0ab"
}

.wi-moon-waning-crescent-2:before {
    content: "\f0ac"
}

.wi-moon-waning-crescent-3:before {
    content: "\f0ad"
}

.wi-moon-waning-crescent-4:before {
    content: "\f0ae"
}

.wi-moon-waning-crescent-5:before {
    content: "\f0af"
}

.wi-moon-waning-crescent-6:before {
    content: "\f0b0"
}

.li_animation_delay>li {
    opacity: 0;
    transform: translateY(15px);
    -webkit-animation: eliment-animation .3s ease-in-out .33333s;
    animation: eliment-animation .3s ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.li_animation_delay>li:nth-child(1) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.li_animation_delay>li:nth-child(2) {
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.li_animation_delay>li:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.li_animation_delay>li:nth-child(4) {
    -webkit-animation-delay: .25s;
    animation-delay: .25s
}

.li_animation_delay>li:nth-child(5) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.li_animation_delay>li:nth-child(6) {
    -webkit-animation-delay: .35s;
    animation-delay: .35s
}

.li_animation_delay>li:nth-child(7) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.li_animation_delay>li:nth-child(8) {
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.li_animation_delay>li:nth-child(9) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.li_animation_delay>li:nth-child(10) {
    -webkit-animation-delay: .55s;
    animation-delay: .55s
}

.right_icon_bar ul>li {
    opacity: 0;
    transform: translateX(15px);
    -webkit-animation: eliment-animation .3s ease-in-out .33333s;
    animation: eliment-animation .3s ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.right_icon_bar ul>li:nth-child(1) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.right_icon_bar ul>li:nth-child(2) {
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.right_icon_bar ul>li:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.right_icon_bar ul>li:nth-child(4) {
    -webkit-animation-delay: .25s;
    animation-delay: .25s
}

.right_icon_bar ul>li:nth-child(5) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.right_icon_bar ul>li:nth-child(6) {
    -webkit-animation-delay: .35s;
    animation-delay: .35s
}

.right_icon_bar ul>li:nth-child(7) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.notifications>li {
    opacity: 0;
    transform: translateY(15px);
    -webkit-animation: eliment-animation .3s ease-in-out .33333s;
    animation: eliment-animation .3s ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.notifications>li:nth-child(1) {
    transform: none;
    -webkit-animation-delay: none;
    animation-delay: none;
    opacity: 1
}

.notifications>li:nth-child(2) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.notifications>li:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.notifications>li:nth-child(4) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.notifications>li:nth-child(5) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.metismenu>li ul li {
    opacity: 0;
    transform: translateX(20px);
    -webkit-animation: eliment-animation .2s ease-in-out .33333s;
    animation: eliment-animation .2s ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.metismenu>li ul li:nth-child(1) {
    transform: none;
    -webkit-animation-delay: none;
    animation-delay: none;
    opacity: 1
}

.metismenu>li ul li:nth-child(2) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.metismenu>li ul li:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.metismenu>li ul li:nth-child(4) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.metismenu>li ul li:nth-child(5) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.metismenu>li ul li:nth-child(6) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.metismenu>li ul li:nth-child(7) {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.metismenu>li ul li:nth-child(8) {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}

.metismenu>li ul li:nth-child(9) {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.metismenu>li ul li:nth-child(10) {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.metismenu>li ul li:nth-child(11) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.metismenu>li ul li:nth-child(12) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s
}

.metismenu>li ul li:nth-child(13) {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s
}

.metismenu>li ul li:nth-child(14) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s
}

.metismenu>li ul li:nth-child(15) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s
}

@-webkit-keyframes eliment-animation {
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes eliment-animation {
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

:root {
    --dark-color: #323A45;
    --heading-color: #222222;
    --font-color: #555555;
    --font-white: #ffffff;
    --font-light: #888888;
    --font-xlight: #f0f0f0;
    --font-ccc: #cccccc;
    --text-muted: #9A9A9A;
    --border-color: #e2e2e2;
    --card-color: #ffffff;
    --white-color: #ffffff;
    --body-color: #fcfcfc;
    --box-shadow: #c7c1c1;
    --box-shadow-light: #ececec;
    --font-hc-color: #888888;
    --bg-hc-color: #323A45
}

[data-theme="dark"] {
    --dark-color: #ffffff;
    --heading-color: #c0c0c0;
    --font-color: #c7c7c7;
    --font-white: #ffffff;
    --font-light: #888888;
    --font-xlight: #333638;
    --font-ccc: #cccccc;
    --text-muted: #80858a;
    --border-color: #3b3f42;
    --card-color: #26282a;
    --white-color: #000000;
    --body-color: #222426;
    --box-shadow: #585858;
    --box-shadow-light: #323536;
    --font-hc-color: #888888;
    --bg-hc-color: #ffffff
}

[data-theme="high-contrast"] {
    --dark-color: #ffffff;
    --heading-color: #ffffff;
    --font-color: #ffffff;
    --font-white: #ffffff;
    --font-light: #000000;
    --font-xlight: #ffffff;
    --font-ccc: #111111;
    --text-muted: #ffffff;
    --border-color: #ffffff;
    --card-color: #000000;
    --white-color: #000000;
    --body-color: #000000;
    --box-shadow: #333333;
    --box-shadow-light: #333333;
    --font-hc-color: #ffffff;
    --bg-hc-color: #000000
}

[class="theme-purple"] {
    --primary-color: #7954AD;
    --primary-color2: #7B54B1;
    --primary-color3: #A37ADB;
    --secondary-color: #E76886;
    --secondary-color2: rgb(223, 126, 148);
    --link-color: #59C4BC;
    --primary-gradient: linear-gradient(45deg, #7B54B1, #E76886)
}

[class="theme-blue"] {
    --primary-color: #2929b9;
    --primary-color2: #4343D3;
    --primary-color3: #6C6CFF;
    --secondary-color: #F99006;
    --secondary-color2: #F92F47;
    --link-color: #87B70A;
    --primary-gradient: linear-gradient(45deg, #2929b9, #87B70A)
}

[class="theme-cyan"] {
    --primary-color: #59C4BC;
    --primary-color2: #33BAB0;
    --primary-color3: #8BCEC9;
    --secondary-color: #637AAE;
    --secondary-color2: #00ACEE;
    --link-color: #E76886;
    --primary-gradient: linear-gradient(45deg, #59C4BC, #637AAE)
}

[class="theme-green"] {
    --primary-color: #38C172;
    --primary-color2: #249D57;
    --primary-color3: #187741;
    --secondary-color: #0957C3;
    --secondary-color2: #5589D2;
    --link-color: #FED500;
    --primary-gradient: linear-gradient(45deg, #38C172, #249D57)
}

[class="theme-orange"] {
    --primary-color: #FFA901;
    --primary-color2: #FF7F00;
    --primary-color3: #FBC200;
    --secondary-color: #600489;
    --secondary-color2: #9439BC;
    --link-color: #38C172;
    --primary-gradient: linear-gradient(45deg, #FFA901, #600489)
}

[class="theme-blush"] {
    --primary-color: #FF6A4A;
    --primary-color2: #F5856D;
    --primary-color3: #FBC200;
    --secondary-color: #87B70A;
    --secondary-color2: #AAD736;
    --link-color: #056CDB;
    --primary-gradient: linear-gradient(45deg, #FF6A4A, #AAD736)
}

[class="theme-red"] {
    --primary-color: #F40000;
    --primary-color2: #F92C2C;
    --primary-color3: #F14F4F;
    --secondary-color: #030886;
    --secondary-color2: #252BCF;
    --link-color: #C7E203;
    --primary-gradient: linear-gradient(45deg, #F40000, #252BCF)
}

body {
    background: var(--body-color);
    color: var(--font-color);
    font-size: 14px
}

body.font-nunito {
    font-family: "Nunito", sans-serif
}

body.font-ubuntu {
    font-family: "Ubuntu", sans-serif
}

body.font-raleway {
    font-family: "Raleway", sans-serif
}

body.font-IBMplex {
    font-family: "IBM Plex Sans", sans-serif
}

a:hover,
a:focus {
    color: var(--link-color);
    text-decoration: none;
    outline: none
}

.page-title {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 24px
}

footer {
    padding: 0 30px;
    padding-bottom: 15px;
    position: absolute;
    right: 0
}

@media screen and (max-width: 768px) {
    footer {
        position: relative;
        text-align: center
    }
}

.copyright {
    margin-bottom: 0;
    font-size: 13px
}

.row-deck>.col,
.row-deck>[class*='col-'] {
    display: flex;
    align-items: stretch
}

.row-deck>.col .card,
.row-deck>[class*='col-'] .card {
    flex: 1 1 auto
}

@media only screen and (max-width: 767px) {
    .hidden-xs {
        display: none
    }
}

@media only screen and (max-width: 992px) {
    .hidden-sm {
        display: none
    }
}

@media only screen and (max-width: 1024px) {
    .hidden-md {
        display: none
    }
}

.rtl_mode {
    direction: rtl;
    text-align: right
}

.rtl_mode .navbar-fixed-top {
    left: 50px
}

.rtl_mode .sidebar {
    left: auto;
    right: 0
}

.rtl_mode .sidebar.open .btn-toggle-offcanvas {
    right: 230px;
    left: auto
}

.rtl_mode .sidebar.open .btn-toggle-offcanvas .fa-arrow-left:before {
    content: "\f061"
}

.rtl_mode .right_icon_bar {
    right: auto;
    left: 0;
    border-left: 0;
    border-right-width: 1px
}

.rtl_mode #main-content {
    float: left;
    margin-right: 0;
    margin-left: 50px
}

.rtl_mode.layout-fullwidth #wrapper .sidebar {
    left: auto;
    right: -280px
}

.rtl_mode.layout-fullwidth #wrapper .navbar-fixed-top {
    left: 50px
}

.rtl_mode .search-form {
    margin-left: 20px
}

.rtl_mode.right_icon_toggle .right_icon_bar {
    left: -50px;
    right: auto
}

.rtl_mode.right_icon_toggle .right_icon_bar ul li:last-child {
    left: -10px;
    right: auto
}

.rtl_mode.right_icon_toggle #main-content {
    margin-left: 0
}

.rtl_mode.right_icon_toggle .navbar-fixed-top {
    left: 0
}

.rtl_mode.right_icon_toggle #wrapper .navbar-fixed-top {
    left: 0
}

.rtl_mode.offcanvas-active #wrapper .sidebar {
    right: 0
}

.rtl_mode .ml-3,
.rtl_mode .mx-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important
}

.rtl_mode .mr-3,
.rtl_mode .mx-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important
}

.rtl_mode .ml-2,
.rtl_mode .mx-2 {
    margin-left: 0 !important;
    margin-right: .5rem !important
}

.rtl_mode .float-right {
    float: left !important
}

.rtl_mode .float-left {
    float: right !important
}

.rtl_mode .text-right {
    text-align: left !important
}

.rtl_mode .text-left {
    text-align: right !important
}

.rtl_mode .input-group-text,
.rtl_mode .form-control,
.rtl_mode .btn-group .btn {
    border-radius: 0
}

.rtl_mode .input-group .btn,
.rtl_mode .input-group .input-group-text {
    border-radius: 0
}

.rtl_mode .custom-select {
    border-radius: 0
}

.rtl_mode .custom-file {
    border-radius: 0
}

.rtl_mode .custom-file .custom-file-label {
    border-radius: 0
}

.rtl_mode .custom-file .custom-file-label:after {
    border-radius: 0
}

.rtl_mode .page-link {
    border-radius: 0 !important
}

.rtl_mode .m-r-15,
.rtl_mode .navbar-nav,
.rtl_mode .new_timeline .header .color-overlay .day-number,
.new_timeline .header .color-overlay .rtl_mode .day-number {
    margin-left: 15px;
    margin-right: 0
}

.rtl_mode .m-r-10,
.rtl_mode .user-account .user-photo,
.user-account .rtl_mode .user-photo,
.rtl_mode .user-account .dropdown .dropdown-menu a i,
.user-account .dropdown .dropdown-menu a .rtl_mode i,
.rtl_mode .right_chat .media .media-object,
.right_chat .media .rtl_mode .media-object,
.rtl_mode .testimonial4 .carousel-info img,
.testimonial4 .carousel-info .rtl_mode img {
    margin-right: 0;
    margin-left: 10px
}

.rtl_mode .ml-auto,
.rtl_mode .mx-auto {
    margin-right: auto !important;
    margin-left: unset !important
}

.rtl_mode .input-group-append {
    margin-right: -1px;
    margin-left: 0
}

.rtl_mode ul {
    padding-right: 0
}

.rtl_mode .fancy-checkbox {
    margin-right: 0;
    margin-left: 10px
}

.rtl_mode .fancy-checkbox input[type="checkbox"]+span:before {
    margin-right: 0;
    margin-left: 10px
}

.rtl_mode .fancy-radio {
    margin-left: 10px;
    margin-right: 0
}

.rtl_mode .fancy-radio input[type="radio"]+span i {
    margin-right: 0;
    margin-left: 5px
}

.rtl_mode .fancy-radio input[type="radio"]+span i::after {
    left: auto;
    right: 3px
}

.rtl_mode .dt-bootstrap4 .dt-buttons+.dataTables_filter {
    float: left
}

.rtl_mode div.dataTables_wrapper div.dataTables_filter {
    text-align: left
}

.rtl_mode div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    margin-right: 5px
}

.rtl_mode .dropdown-lg .dropdown-menu {
    left: 0 !important;
    right: auto !important
}

.rtl_mode .user-account {
    text-align: right
}

.rtl_mode .user-account .user-photo {
    margin-left: 10px;
    margin-right: 0
}

.rtl_mode .user-account .dropdown {
    text-align: right
}

.rtl_mode .sidebar-nav .metismenu ul a {
    padding: 5px 49px 15px 5px
}

.rtl_mode .sidebar-nav .metismenu ul a::before {
    left: auto;
    right: 21px
}

.rtl_mode .sidebar-nav .metismenu ul ul::before {
    right: 47px;
    left: auto
}

.rtl_mode .sidebar-nav .metismenu ul ul a {
    padding: 5px 60px 5px 15px
}

.rtl_mode .metismenu .has-arrow:after {
    left: 1.5em;
    right: auto
}

.rtl_mode .navbar-nav .dropdown-menu {
    left: 0;
    right: auto;
    text-align: right
}

.rtl_mode .navbar-nav ul.notifications li>a .media .media-left {
    margin-right: 0;
    margin-left: 20px
}

.rtl_mode .card .header .header-dropdown {
    right: auto;
    left: 10px
}

.rtl_mode .card .header .header-dropdown li .dropdown-menu {
    right: auto !important;
    left: 0 !important
}

.rtl_mode .right_chat .media .media-object {
    margin-left: 10px;
    margin-right: 0
}

.rtl_mode .right_chat .status {
    right: 23px;
    left: auto
}

.rtl_mode .top_widget .icon {
    left: 20px;
    right: auto
}

.rtl_mode .team-info li+li {
    margin-right: -10px;
    margin-left: 0
}

.rtl_mode .chat .chat-header .chat-about {
    float: right;
    padding-left: 0;
    padding-right: 10px
}

.rtl_mode .chat .chat-header img {
    float: right
}

.rtl_mode .chat .chat-history .other-message:after {
    right: 93%;
    left: auto
}

.rtl_mode .chat .chat-history .my-message:after {
    right: 30px;
    left: auto
}

.rtl_mode .people-list img {
    float: right
}

.rtl_mode .people-list .about {
    float: right;
    padding-right: 8px;
    padding-left: 0
}

.rtl_mode .c_list .c_name {
    margin-left: 0;
    margin-right: 10px
}

.rtl_mode .single_post .footer .stats {
    float: left
}

.rtl_mode .file_manager .file .file-name small .date {
    float: left
}

.rtl_mode .top_counter .icon {
    margin-right: 0;
    margin-left: 15px
}

.rtl_mode .feeds_widget li .feeds-left {
    float: right
}

.rtl_mode .list-widget .badge {
    float: left
}

.rtl_mode .basic-list li span {
    float: left
}

.rtl_mode .new_timeline>ul {
    padding: 1em 1.2em 0 0
}

.rtl_mode .new_timeline>ul::before {
    right: 1.6em;
    left: auto
}

.rtl_mode .new_timeline .bullet {
    margin-right: 0;
    margin-left: 5px
}

.rtl_mode .weather .city i {
    right: auto;
    left: 0
}

.rtl_mode .c_review .avatar {
    float: right
}

.rtl_mode .media.mleft .media-left,
.rtl_mode .bs-example .media .media-left {
    margin-left: 20px;
    margin-right: 0
}

.rtl_mode .profile-header {
    text-align: center
}

.rtl_mode .timeline-item {
    border-left: 0;
    border-right-width: 1px
}

.rtl_mode .timeline-item:after {
    right: -8px;
    left: auto
}

.rtl_mode .invoice1 .info,
.rtl_mode .invoice1 .logo,
.rtl_mode .invoice1 .clientlogo {
    float: right;
    margin-left: 15px
}

.rtl_mode .invoice1 .invoice-top .title {
    float: left
}

.rtl_mode .mail-inbox .mail-left .mail-side .nav li a .badge {
    margin-right: auto;
    margin-left: 0
}

.rtl_mode .mail-inbox .mail-right .mail-list ul li .mail-detail-left,
.rtl_mode .mail-inbox .mail-right .mail-list ul li .mail-detail-right {
    float: right
}

.rtl_mode .mail-inbox .mail-right .mail-list ul li .hover-action {
    left: 0;
    right: auto
}

.rtl_mode .mail-inbox .mail-right .mail-list ul li .mail-detail-left .mail-star {
    left: 13px;
    right: auto
}

.rtl_mode .mail-inbox .mail-right .mail-list ul li .mail-detail-right {
    padding-left: 70px;
    padding-right: 0
}

.rtl_mode .mail-inbox .mail-right .mail-list ul li .mail-detail-right span.time {
    left: 0;
    right: auto
}

.rtl_mode .c3 {
    direction: ltr
}

.rtl_mode .w_bitcoin .body {
    left: 0
}

.rtl_mode .rs-control {
    direction: ltr
}

.rtl_mode .treeview span.icon {
    margin-left: 5px;
    margin-right: 0
}

.rtl_mode .knob {
    direction: ltr
}

.rtl_mode .knob {
    margin-right: -79px !important;
    margin-left: auto !important
}

.rtl_mode*[dir="rtl"] .metismenu .arrow {
    float: left
}

*[dir="rtl"] .metismenu .plus-times {
    float: left
}

*[dir="rtl"] .metismenu .plus-minus {
    float: left
}

*[dir="rtl"] .metismenu .has-arrow::after {
    right: auto;
    left: 1em;
    transform: rotate(-93deg) translate(0, -50%)
}

.metismenu .arrow {
    float: right;
    line-height: 1.42857
}

.metismenu .collapse {
    display: none
}

.metismenu .collapse.in {
    display: block
}

.metismenu .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility
}

.metismenu .has-arrow {
    position: relative
}

.metismenu .has-arrow:after {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    position: absolute;
    content: '\f0d7';
    font-family: "FontAwesome";
    font-size: 10px;
    right: 1.5em;
    transform: rotate(0deg) translate(0, -50%);
    transform-origin: top;
    top: 50%
}

.metismenu .active>.has-arrow::after,
.metismenu .has-arrow[aria-expanded="true"]::after {
    transform: rotate(93deg) translate(0, -50%)
}

*[dir="rtl"] .metismenu .active>.has-arrow::after,
*[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
    transform: rotate(225deg) translate(0, -50%)
}

.badge {
    padding: 4px 8px;
    text-transform: uppercase;
    line-height: 12px;
    border: 1px solid transparent;
    font-weight: 400;
    vertical-align: bottom
}

.badge.badge-icon {
    padding: 0.4em 0.55em
}

.badge.badge-icon i {
    font-size: 0.8em
}

.badge.badge-default {
    background: transparent;
    border-color: var(--text-muted);
    color: var(--text-muted)
}

.badge.badge-primary {
    background: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color)
}

.badge.badge-info {
    background: transparent;
    border-color: #3C89DA;
    color: #3C89DA
}

.badge.badge-success {
    background: transparent;
    border-color: #22af46;
    color: #22af46
}

.badge.badge-warning {
    background: transparent;
    border-color: #f3ad06;
    color: #f3ad06
}

.badge.badge-danger {
    background: transparent;
    border-color: #de4848;
    color: #de4848
}

html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
    height: 100%
}

#main-content {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 330px);
    float: right;
    position: relative;
    margin-top: 60px;
    margin-right: 50px;
    padding: 0 20px;
    background: var(--body-color)
}

.right_icon_toggle .navbar-fixed-top {
    width: calc(100% - 280px)
}

.right_icon_toggle #main-content {
    width: calc(100% - 280px);
    margin-right: 0
}

.right_icon_toggle .right_icon_bar {
    right: -50px;
    z-index: 9
}

.right_icon_toggle .right_icon_bar ul li:last-child {
    right: -10px
}

.right_icon_toggle .right_icon_bar ul li:last-child i:before {
    content: "\f104"
}

.right_icon_toggle.layout-fullwidth .navbar-fixed-top,
.right_icon_toggle.layout-fullwidth #main-content {
    width: 100% !important
}

.offcanvas-active .sidebar {
    left: 5px
}

.right_icon_bar {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: fixed;
    text-align: center;
    right: 0;
    top: 0;
    width: 50px;
    height: 100vh;
    padding: 15PX 0;
    background: var(--card-color);
    border: solid;
    border-left-width: 1px;
    border-top: 0;
    border-bottom: 0;
    border-color: var(--border-color)
}

.right_icon_bar ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.right_icon_bar ul li {
    display: flex;
    justify-content: center
}

.right_icon_bar ul li:last-child {
    position: fixed;
    bottom: 60px
}

.right_icon_bar ul a {
    padding: 10px;
    color: var(--dark-color);
    display: block;
    width: 50px;
    height: 40px
}

.right_icon_bar ul a:hover {
    color: var(--primary-color)
}

.vertical-align-wrap {
    display: table;
    position: relative;
    width: 100%;
    height: 100vh
}

.vertical-align-middle {
    display: table-cell;
    vertical-align: middle
}

.card {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background: var(--card-color);
    border: 1px dashed var(--border-color);
    margin-bottom: 30px;
    position: relative;
    width: 100%
}

.card .header {
    color: var(--dark-color);
    padding: 20px;
    position: relative;
    box-shadow: none;
    margin-bottom: 0
}

.card .header .header-dropdown {
    position: absolute;
    list-style: none;
    top: 11px;
    right: 10px
}

.card .header .header-dropdown li a {
    color: var(--text-muted);
    padding: 5px 10px
}

.card .header .header-dropdown li .dropdown-menu {
    background: var(--dark-color);
    border-radius: .1875rem;
    overflow: hidden;
    transform: none !important;
    left: auto !important;
    right: 0;
    padding: 10px;
    box-shadow: 0px 2px 20px 0px var(--box-shadow);
    border: 0
}

.card .header .header-dropdown li .dropdown-menu li {
    display: block !important
}

.card .header .header-dropdown li .dropdown-menu li a {
    color: var(--font-light);
    padding: 10px;
    white-space: nowrap;
    font-size: 14px
}

.card .header .header-dropdown li .dropdown-menu li a:hover {
    color: var(--font-white)
}

.card .header .header-dropdown i {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--text-muted);
    font-size: 14px
}

.card .header h2 {
    color: var(--heading-color);
    font-size: 17px;
    position: relative
}

.card .header h2 small {
    color: var(--font-color);
    line-height: 15px;
    font-size: 13px
}

.card .header h2 small a {
    font-weight: 700;
    color: var(--font-color)
}

.card .p-15 {
    padding: 15px !important
}

.card .body {
    color: var(--font-color);
    font-weight: 400;
    padding: 20px
}

.navbar-fixed-top {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 5px 20px;
    position: fixed;
    top: 0px;
    left: 280px;
    width: calc(100% - 330px);
    z-index: 99;
    background: var(--body-color)
}

.navbar-fixed-top .navbar-brand {
    padding: 15px 0;
    margin: 0;
    position: relative
}

@media screen and (max-width: 992px) {
    .navbar-fixed-top .navbar-brand {
        padding: 15px
    }
}

.navbar-fixed-top .navbar-brand a {
    font-weight: 700;
    color: var(--dark-color);
    font-size: 22px
}

.navbar-fixed-top .navbar-brand button {
    padding: 0;
    color: var(--text-muted);
    font-size: 20px;
    line-height: .7;
    border: none;
    background: none;
    outline: none
}

.navbar-fixed-top .navbar-brand .btn-toggle-offcanvas {
    display: none
}

@media screen and (max-width: 1280px) {
    .navbar-fixed-top .navbar-brand .btn-toggle-offcanvas {
        display: inline-block
    }
}

.navbar-fixed-top .navbar-brand img {
    width: 85px;
    vertical-align: text-top
}

.navbar-fixed-top .navbar-right {
    display: flex
}

.navbar-nav .icon-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 42px
}

.navbar-nav .icon-menu i {
    color: var(--font-color)
}

.navbar-nav .icon-menu::after {
    display: none
}

.navbar-nav .icon-menu .notification-dot {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 12px;
    right: 7px;
    background-color: var(--dark-color)
}

.navbar-nav .dropdown-menu {
    -moz-box-shadow: 0px 2px 20px 0px var(--box-shadow);
    -webkit-box-shadow: 0px 2px 20px 0px var(--box-shadow);
    box-shadow: 0px 2px 20px 0px var(--box-shadow);
    background: var(--white-color);
    top: 80%;
    position: absolute;
    right: 0;
    left: auto;
    border: 0;
    padding: 0
}

.navbar-nav ul.notifications {
    background: var(--white-color);
    overflow: hidden;
    width: 350px
}

@media screen and (max-width: 768px) {
    .navbar-nav ul.notifications {
        width: calc(100% - 20px);
        margin: 0 10px
    }
}

.navbar-nav ul.notifications li.header {
    padding: 30px 20px;
    margin-bottom: 10px;
    color: var(--font-white);
    background: var(--primary-gradient)
}

.navbar-nav ul.notifications li.footer a {
    padding: 20px;
    color: var(--link-color)
}

.navbar-nav ul.notifications li.footer a:hover,
.navbar-nav ul.notifications li.footer a:focus {
    background: transparent
}

.navbar-nav ul.notifications li:last-child {
    border-bottom: none
}

.navbar-nav ul.notifications li i {
    font-size: 20px;
    vertical-align: text-top
}

.navbar-nav ul.notifications li>a {
    padding: 10px 20px;
    color: var(--font-color)
}

.navbar-nav ul.notifications li .timestamp {
    font-size: 12px;
    color: var(--text-muted)
}

@media screen and (max-width: 767px) {
    .navbar-nav {
        margin-right: 0
    }

    .navbar-nav>li {
        display: table-cell;
        position: inherit;
        text-align: center
    }

    .navbar-nav .dropdown-menu>li>a {
        white-space: normal
    }

    .navbar-nav .open .dropdown-menu {
        -moz-box-shadow: 0px 2px 6px 0px var(--box-shadow);
        -webkit-box-shadow: 0px 2px 6px 0px var(--box-shadow);
        box-shadow: 0px 2px 6px 0px var(--box-shadow);
        position: absolute;
        width: 100%;
        background: var(--white-color)
    }

    .navbar-nav .open .dropdown-menu>li>a {
        padding: 3px 20px
    }

    .navbar-nav .open .dropdown-menu.notifications>li>a {
        padding: 12px 15px
    }
}

@media screen and (max-width: 768px) {

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0
    }
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    background-color: var(--font-xlight);
    color: var(--font-color)
}

.nav-tabs>li>a {
    color: inherit;
    font-weight: 600
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    background-color: var(--font-xlight)
}

.nav-tabs .dropdown-menu {
    transform: none !important
}

.tab-content {
    padding: 15px
}

.nav-tabs-colored>li.active>a,
.nav-tabs-colored>li.active>a:hover,
.nav-tabs-colored>li.active>a:focus {
    background-color: var(--font-xlight);
    color: var(--font-color)
}

.tab-content-colored {
    background-color: var(--font-xlight)
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    border-color: var(--border-color)
}

.dropdown-menu>li>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    color: var(--font-color)
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    background-color: var(--secondary-color)
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    background-color: var(--font-xlight);
    color: var(--font-color)
}

.nav-pills>li>a {
    color: inherit
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary-color)
}

.btn-toggle-fullwidth {
    z-index: 99;
    margin-top: -6px;
    padding-left: 0 !important
}

.btn-toggle-fullwidth i {
    margin: 0 !important
}

@media screen and (max-width: 1280px) {
    .btn-toggle-fullwidth {
        display: none
    }
}

.nav-tabs-new>li>a {
    color: var(--font-color);
    border: 1px solid var(--font-xlight);
    border-radius: 30px;
    padding: 7px 23px;
    background-color: transparent
}

.nav-tabs-new>li>a:hover,
.nav-tabs-new>li>a:focus {
    background-color: var(--primary-color);
    color: var(--font-white)
}

.nav-tabs-new>li>a.active {
    border: 1px solid var(--primary-color) !important;
    background: var(--primary-color);
    color: var(--font-white)
}

.nav-tabs-new2 {
    border-bottom: 1px solid var(--border-color)
}

.nav-tabs-new2>li>a {
    color: var(--font-color);
    padding: 7px 20px;
    background-color: transparent;
    border-bottom: transparent;
    font-size: 15px
}

.nav-tabs-new2>li>a:hover,
.nav-tabs-new2>li>a:focus {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color) !important
}

.nav-tabs-new2>li>a.active {
    border-bottom: 2px solid var(--primary-color) !important;
    background-color: transparent;
    color: var(--primary-color)
}

.dropdown-lg .dropdown-menu {
    top: 40px !important;
    right: 0 !important;
    width: 350px;
    left: auto !important;
    transform: inherit !important;
    padding: 20px;
    box-shadow: 0 6px 12px var(--box-shadow)
}

.breadcrumb-item.active {
    color: var(--text-muted)
}

.sidebar {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: var(--card-color);
    width: 280px;
    height: 100vh;
    box-shadow: inset -2px -2px 8px 0 var(--box-shadow-light);
    position: fixed;
    left: 0;
    padding: 0 20px;
    z-index: 11;
    border-right: 1px solid var(--border-color);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.sidebar::-webkit-scrollbar {
    width: 5px
}

.sidebar.open {
    left: 0 !important;
    z-index: 999 !important
}

.sidebar.open .btn-toggle-offcanvas {
    display: block;
    position: absolute;
    left: 230px;
    top: 24px;
    outline: 0;
    border: 0
}

.sidebar.primary {
    box-shadow: none;
    background: var(--primary-color);
    color: var(--font-white)
}

.sidebar.primary hr {
    opacity: .2
}

.sidebar.primary .sidebar-nav .metismenu>li.active {
    border-color: var(--white-color)
}

.sidebar.primary .sidebar-nav .metismenu>li.active>a {
    color: var(--font-white)
}

.sidebar.primary .sidebar-nav .metismenu ul a {
    color: var(--font-white);
    opacity: .8
}

.sidebar.primary .right_chat .media .name {
    color: var(--font-white)
}

.sidebar.primary .right_chat .media .message {
    color: var(--font-white);
    opacity: .6
}

.sidebar.primary .fancy-checkbox input[type="checkbox"]:checked+span:before {
    border-color: var(--white-color);
    background: var(--white-color)
}

.sidebar .btn-toggle-offcanvas {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: none
}

.sidebar .nav-tabs {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: var(--dark-color);
    padding: 0 5px;
    border: 0;
    margin-bottom: 15px
}

.sidebar .nav-tabs .nav-link {
    border: 0;
    font-weight: 400;
    color: var(--font-light)
}

.sidebar .nav-tabs .nav-link.active,
.sidebar .nav-tabs .nav-link:hover,
.sidebar .nav-tabs .nav-link:focus {
    background-color: transparent;
    color: var(--primary-color)
}

.layout-fullwidth #wrapper .navbar-fixed-top {
    width: calc(100% - 50px);
    left: 0
}

.layout-fullwidth #wrapper .sidebar {
    left: -280px
}

.layout-fullwidth #wrapper .sidebar.ps {
    overflow: visible !important
}

.layout-fullwidth #wrapper #main-content {
    width: calc(100% - 50px)
}

.user-account {
    margin: 20px 0
}

.user-account .user-photo {
    width: 50px;
    vertical-align: top;
    border: 2px solid var(--border-color)
}

.user-account .user-name {
    color: inherit
}

.user-account .user-name:hover,
.user-account .user-name:focus {
    text-decoration: none
}

.user-account .dropdown .dropdown-menu {
    transform: none !important;
    border: none;
    box-shadow: 0px 2px 20px 0px var(--box-shadow);
    padding: 15px;
    background: var(--dark-color);
    border-radius: .55rem
}

.user-account .dropdown .dropdown-menu li.divider {
    border-bottom: 1px solid var(--border-color);
    margin: 10px 0
}

.user-account .dropdown .dropdown-menu a {
    padding: 10px;
    font-size: 14px;
    color: var(--font-light)
}

.user-account .dropdown .dropdown-menu a:hover {
    color: var(--primary-color)
}

.user-account .dropdown .dropdown-menu a:hover i {
    color: var(--primary-color)
}

.user-account .dropdown .dropdown-menu a i {
    font-size: 14px
}

.sidebar-nav ul {
    list-style: none
}

.sidebar-nav .metismenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.sidebar-nav .metismenu>li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    border: 1px solid transparent
}

.sidebar-nav .metismenu>li.active {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border-color: var(--primary-color)
}

.sidebar-nav .metismenu>li.active>a {
    color: var(--primary-color);
    border-radius: 20px 20px 0 0
}

.sidebar-nav .metismenu>li.active ul {
    border-radius: 0 0 20px 20px;
    margin-bottom: 15px !important
}

.sidebar-nav .metismenu>li .active>a {
    font-weight: 700
}

.sidebar-nav .metismenu>li i {
    width: 28px
}

.sidebar-nav .metismenu a {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    color: var(--dark-color);
    position: relative;
    padding: 13px 20px;
    outline-width: 0;
    font-size: 15px
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active {
    text-decoration: none
}

.sidebar-nav .metismenu ul a {
    color: var(--font-color);
    font-size: 14px;
    position: relative;
    padding: 5px 15px 5px 49px
}

.sidebar-nav .metismenu ul a::before {
    content: '--';
    position: absolute;
    left: 21px;
    top: 4px
}

.sidebar-nav .metismenu ul ul {
    position: relative
}

.sidebar-nav .metismenu ul ul::before {
    position: absolute;
    left: 27px;
    height: 100%;
    width: 1px;
    content: "";
    background: var(--border-color)
}

.sidebar-nav .metismenu ul ul a {
    padding: 5px 15px 5px 60px
}

.sidebar-nav .metismenu ul ul a:before {
    left: 46px
}

.sidebar-nav .metismenu ul.collapse a:hover,
.sidebar-nav .metismenu ul.collapse a:focus {
    text-decoration: none
}

.sidebar-nav .metismenu .has-arrow::after {
    color: var(--text-muted)
}

ul.choose-skin {
    display: flex;
    justify-content: start
}

ul.choose-skin li {
    margin-right: 5px
}

ul.choose-skin li div {
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    height: 22px;
    width: 22px;
    position: relative
}

ul.choose-skin li div.purple {
    background: #7c48d4
}

ul.choose-skin li div.blue {
    background: #0c85e0
}

ul.choose-skin li div.cyan {
    background: #34c2b1
}

ul.choose-skin li div.green {
    background: #42d182
}

ul.choose-skin li div.orange {
    background: #ff812d
}

ul.choose-skin li div.blush {
    background: #e0455a
}

ul.choose-skin li div.red {
    background: #fe2053
}

ul.choose-skin li.active div::before {
    position: absolute;
    color: var(--font-white);
    font-size: 12px;
    top: 2px;
    left: 5px;
    content: '\f00c';
    font-family: 'FontAwesome'
}

.question li {
    padding: 3px 0
}

.question li a {
    position: relative
}

.question li a:before {
    content: '--';
    left: 0;
    position: absolute
}

.question .help-search-form i {
    font-size: 12px
}

label {
    font-weight: 600
}

.search-form {
    position: relative
}

.search-form .form-control {
    height: 40px;
    border: 0;
    padding-left: 20px;
    background: transparent
}

.search-form i {
    font-size: 12px
}

.search-form .btn {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    top: 10px;
    left: 0;
    border: none;
    color: var(--font-color);
    background-color: transparent
}

.search-form .btn:hover,
.search-form .btn:focus {
    background-color: inherit;
    border-left: none;
    border-color: inherit
}

@media screen and (max-width: 767px) {
    .search-form {
        display: none
    }
}

.fancy-checkbox {
    margin-right: 10px
}

.fancy-checkbox,
.fancy-checkbox label {
    font-weight: normal
}

.fancy-checkbox input[type="checkbox"] {
    display: none
}

.fancy-checkbox input[type="checkbox"]+span {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    cursor: pointer;
    position: relative
}

.fancy-checkbox input[type="checkbox"]+span:before {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    bottom: 1px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    content: "";
    border: 1px solid var(--border-color);
    border-radius: 3px
}

.fancy-checkbox input[type="checkbox"]:checked+span:before {
    font-family: FontAwesome;
    content: '\f00c';
    font-size: 10px;
    color: var(--primary-color);
    border-color: var(--primary-color);
    text-align: center;
    line-height: 21px
}

.fancy-checkbox.custom-color-green input[type="checkbox"]:checked+span:before {
    color: #22af46;
    background-color: var(--font-white)
}

.fancy-checkbox.custom-bgcolor-green input[type="checkbox"]:checked+span:before {
    color: var(--font-white);
    background-color: #22af46;
    border-color: #1b8d38
}

.fancy-radio {
    margin-right: 10px
}

.fancy-radio,
.fancy-radio label {
    font-weight: normal
}

.fancy-radio input[type="radio"] {
    display: none
}

.fancy-radio input[type="radio"]+span {
    display: block;
    cursor: pointer;
    position: relative
}

.fancy-radio input[type="radio"]+span i {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    bottom: 1px;
    content: "";
    border: 1px solid var(--border-color);
    width: 18px;
    height: 18px;
    margin-right: 5px
}

.fancy-radio input[type="radio"]:checked+span i:after {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    content: '';
    width: 10px;
    height: 10px;
    background-color: #7b848c
}

.fancy-radio.custom-color-green input[type="radio"]:checked+span i:after {
    background-color: var(--primary-color)
}

.fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i {
    background-color: var(--primary-color)
}

.fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i:after {
    background-color: var(--font-white)
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color)
}

.input-group-addon .fancy-radio,
.input-group-addon .fancy-checkbox {
    margin: 0;
    position: relative;
    top: 1px
}

.input-group-addon .fancy-radio input[type="radio"]+span i,
.input-group-addon .fancy-checkbox input[type="checkbox"]+span {
    margin: 0
}

.input-group-addon .fancy-radio input[type="radio"]+span i:before,
.input-group-addon .fancy-checkbox input[type="checkbox"]+span:before {
    margin: 0
}

.input-group-text {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--text-muted)
}

.form-control {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: var(--card-color);
    border-color: var(--border-color);
    font-size: 14px;
    height: auto
}

.form-control:focus {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border-color: var(--primary-color)
}

.help-block {
    font-size: 13px;
    color: var(--text-muted)
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a {
    padding: 5px 15px;
    display: block;
    outline: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox {
    padding: 0;
    font-size: 14px
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox:before {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    bottom: 1px;
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    background-color: #fafafa
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox input[type="checkbox"] {
    display: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.checkbox:before {
    font-family: 'FontAwesome';
    content: '\f00c';
    font-size: 12px;
    color: #99A1A7;
    text-align: center;
    line-height: 15px;
    background-color: var(--font-xlight)
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active .checkbox {
    color: var(--font-white)
}

.multiselect-custom+.btn-group ul.multiselect-container>li:hover {
    background-color: var(--bg-hc-color)
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a {
    padding: 5px 15px;
    display: block;
    outline: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio {
    padding: 0
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio:before {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    bottom: 1px;
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    background-color: #fafafa
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio input[type="radio"] {
    display: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.radio:before {
    font-family: 'FontAwesome';
    content: '\f111';
    color: var(--text-muted);
    font-size: 8px;
    text-align: center;
    line-height: 15px
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active .radio {
    color: var(--font-white)
}

.multiselect-custom+.btn-group ul.multiselect-container>li:hover {
    background-color: var(--bg-hc-color)
}

.multiselect-search {
    -moz-border-radius-topright: 2px !important;
    -webkit-border-top-right-radius: 2px !important;
    border-top-right-radius: 2px !important;
    -moz-border-radius-bottomright: 2px !important;
    -webkit-border-bottom-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important
}

.btn.multiselect-clear-filter {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    top: 2px;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: transparent;
    z-index: 9 !important;
    outline: none
}

.btn.multiselect-clear-filter:hover,
.btn.multiselect-clear-filter:focus {
    background: transparent;
    outline: none
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active:active {
    background-color: var(--secondary-color)
}

.parsley-errors-list {
    margin-top: 8px
}

.parsley-errors-list li {
    color: #de4848;
    font-size: 0.9em;
    margin-top: 3px
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    background-color: #fbf5f5;
    border-color: #efd8d8
}

.form-control.parsley-error:focus {
    border-color: #e1b3b3
}

.form-control.parsley-success:focus {
    border-color: #afd99d
}

.md-editor>textarea {
    padding: 10px
}

.dropify-wrapper {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: var(--font-xlight);
    color: var(--text-muted);
    border: 1px solid var(--border-color)
}

.dropify-wrapper .dropify-preview {
    background-color: var(--font-xlight)
}

.dropify-wrapper .dropify-message p {
    font-size: 14px
}

.dropify-wrapper:hover {
    background-image: linear-gradient(-45deg, var(--border-color) 25%, transparent 25%, transparent 50%, var(--border-color) 50%, var(--border-color) 75%, transparent 75%, transparent)
}

select.form-control:not([size]):not([multiple]) {
    height: 36px
}

.c_multiselect .btn-group {
    width: 100%
}

.c_multiselect .btn-group .btn {
    width: 100%;
    text-align: left
}

.c_multiselect .dropdown-menu {
    width: 100%;
    background: var(--card-color);
    border-color: var(--border-color)
}

.c_multiselect .dropdown-menu .multiselect-group {
    color: var(--font-color)
}

.c_multiselect .checkbox {
    color: var(--font-color)
}

.c_multiselect .dropdown-toggle::after {
    right: 15px;
    position: absolute;
    top: 15px
}

.bootstrap-tagsinput {
    background-color: var(--card-color);
    border: 1px solid var(--border-color)
}

.cd-horizontal-timeline {
    color: var(--font-color)
}

.cd-horizontal-timeline .filling-line {
    background-color: var(--primary-color)
}

.cd-horizontal-timeline .events a.selected {
    color: var(--primary-color)
}

.cd-horizontal-timeline .events a.selected:after {
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: var(--primary-color)
}

.modal-content {
    background-color: var(--card-color);
    border-color: var(--border-color)
}

.modal-content .modal-header,
.modal-content .modal-footer {
    border-color: var(--border-color)
}

.auth-main::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
    height: 100%;
    z-index: -1;
    background: transparent
}

@media screen and (max-width: 640px) {
    .auth-main::before {
        width: 100%
    }
}

.auth-main .card {
    padding: 10px;
    margin-bottom: 0
}

.auth-box {
    width: 380px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.admin-auth-box{
    width: 380px;
    height: auto;
    margin-left: auto;
    margin-right: 2%;
}

.auth-box .top img {
    width: 130px
}

.auth-box .form-auth-small .element-left {
    float: left
}

.auth-box .form-auth-small .element-right {
    float: right
}

.auth-box .lead {
    font-size: 18px
}

.auth-box .helper-text {
    color: var(--text-muted)
}

.auth-box .btn-signin-social {
    padding: 10px 22px;
    width: 100%;
    background-color: var(--font-white);
    border-color: var(--border-color)
}

.auth-box .btn-signin-social:hover,
.auth-box .btn-signin-social:focus {
    background-color: var(--font-xlight)
}

.auth-box .btn-signin-social i {
    font-size: 16px;
    margin: 5px
}

@media screen and (max-width: 992px) {
    .auth-box {
        width: 80%;
        margin: 0 auto
    }
}

@media screen and (max-width: 640px) {
    .auth-box {
        width: 90%
    }
}

.btn {
    font-size: 14px
}

.btn.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.btn.btn-secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color)
}

.btn.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color)
}

.btn.btn-outline-primary:hover {
    color: var(--font-white);
    background-color: var(--primary-color)
}

.btn-default {
    color: var(--bg-hc-color);
    background-color: var(--font-xlight);
    border-color: var(--font-xlight);
    padding: 6px 18px
}

.btn-default:hover,
.btn-default:focus,
.btn-default:focus-within {
    color: var(--primary-color)
}

.separator-linethrough {
    position: relative;
    margin: 30px 0
}

.separator-linethrough span {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    padding: 0 10px;
    position: inherit;
    font-size: 18px;
    background-color: var(--font-white);
    z-index: 9
}

.separator-linethrough:after {
    position: absolute;
    top: 50%;
    width: 100%;
    content: '';
    border-top: 1px solid var(--border-color);
    z-index: 0
}

.block-header {
    color: var(--primary-color)
}

.block-header h2 {
    font-size: 20px
}

.block-header .breadcrumb {
    background: transparent;
    font-size: 13px
}

.block-header .breadcrumb a {
    color: var(--text-muted)
}

#toast-container>div {
    opacity: 1;
    filter: alpha(opacity=100)
}

#toast-container>div,
#toast-container>div:hover {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

#toast-container .toast-close-button {
    opacity: .3;
    filter: alpha(opacity=30);
    color: var(--font-white);
    top: -11px;
    text-shadow: none
}

#toast-container .toast-close-button:hover,
#toast-container .toast-close-button:focus {
    opacity: .5;
    filter: alpha(opacity=50)
}

#toast-container .toast-info {
    background-color: #3C89DA
}

#toast-container .toast-info .toast-close-button {
    color: var(--font-white)
}

#toast-container .toast-success {
    background-color: #22af46
}

#toast-container .toast-success .toast-close-button {
    color: #115a24
}

#toast-container .toast-warning {
    background-color: #f3ad06
}

#toast-container .toast-warning .toast-close-button {
    color: #8f6604
}

#toast-container .toast-error {
    background-color: #de4848
}

#toast-container .toast-error .toast-close-button {
    color: #8d1919
}

#toast-container .toast a {
    text-decoration: underline
}

#toast-container .toast a:hover,
#toast-container .toast a:focus {
    text-decoration: none;
    color: var(--font-white)
}

#toast-container .toast .toast-message {
    font-size: 14px;
    line-height: 1.4
}

.list-group-item {
    background-color: var(--card-color);
    border-color: var(--border-color)
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;
    margin: 0
}

.toggle-switch input {
    display: none
}

.toggle-switch input:checked+.toggle-switch-slider::before {
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.toggle-switch input:checked+.toggle-switch-slider::before {
    -webkit-transform: translateX(1.525rem);
    -ms-transform: translateX(1.525rem);
    transform: translateX(1.525rem)
}

.toggle-switch input:checked+.toggle-switch-slider {
    border: 0.0525rem solid var(--primary-color)
}

.toggle-switch .toggle-switch-slider {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.0625rem solid var(--border-color);
    border-radius: .18rem;
    background-color: transparent
}

.toggle-switch .toggle-switch-slider:before {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: .0625rem;
    bottom: 2px;
    border-radius: .18rem;
    background-color: var(--border-color)
}

.toggle-switch+span {
    width: calc(100% - 50px)
}

.knob {
    margin-left: -79px !important;
    margin-right: auto !important
}

.knob2 {
    margin-left: -79px !important;
    margin-right: auto !important
}

.avatar {
    width: 32px;
    border: 1px solid var(--white-color)
}

.avatar.sm {
    width: 28px
}

.avatar.lg {
    width: 40px
}

.accordion .card-header .btn {
    padding: 10px 20px;
    width: 100%;
    font-size: 16px
}

.progress {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: var(--font-xlight)
}

.progress.wide {
    width: 60px
}

.progress .progress-bar {
    -webkit-transition: width 3s ease;
    -moz-transition: width 3s ease;
    -ms-transition: width 3s ease;
    -o-transition: width 3s ease;
    transition: width 3s ease;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress.progress-lg {
    height: 28px
}

.progress.progress-sm {
    height: 12px
}

.progress.progress-xs {
    height: 5px
}

.progress.progress-sm .progress-bar,
.progress.progress-xs .progress-bar {
    text-indent: -9999px
}

.progress-bar {
    background-color: var(--secondary-color)
}

.progress-bar-success {
    background-color: #22af46
}

.progress-bar-warning {
    background-color: #f3ad06
}

.progress-bar-danger {
    background-color: #de4848
}

.progress-bar-info {
    background-color: #3C89DA
}

.progress-transparent.custom-color-blue {
    background-color: rgba(14, 155, 226, 0.2)
}

.progress-transparent.custom-color-blue .progress-bar {
    background-color: #0E9BE2
}

.progress-transparent.custom-color-green {
    background-color: rgba(124, 172, 37, 0.2)
}

.progress-transparent.custom-color-green .progress-bar {
    background-color: #7CAC25
}

.progress-transparent.custom-color-orange {
    background-color: rgba(255, 68, 2, 0.2)
}

.progress-transparent.custom-color-orange .progress-bar {
    background-color: #FF4402
}

.progress-transparent.custom-color-purple {
    background-color: rgba(171, 125, 246, 0.2)
}

.progress-transparent.custom-color-purple .progress-bar {
    background-color: #AB7DF6
}

.progress-transparent.custom-color-yellow {
    background-color: rgba(243, 187, 35, 0.2)
}

.progress-transparent.custom-color-yellow .progress-bar {
    background-color: #F3BB23
}

.progress-transparent.custom-color-lightseagreen {
    background-color: rgba(32, 178, 170, 0.2)
}

.progress-transparent.custom-color-lightseagreen .progress-bar {
    background-color: #20b2aa
}

.tooltip-inner {
    background-color: var(--font-color)
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100)
}

.tooltip.left .tooltip-arrow {
    border-left-color: var(--font-color)
}

.tooltip.right .tooltip-arrow {
    border-right-color: var(--font-color)
}

.tooltip.top .tooltip-arrow {
    border-top-color: var(--font-color)
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: var(--font-color)
}

.popover {
    -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    font-family: var(--font-family);
    border-color: var(--border-color)
}

.popover-title {
    font-weight: 600;
    background-color: var(--font-xlight);
    color: var(--font-color)
}

.panel-group .panel .panel-heading {
    padding: 0
}

.panel-group .panel .panel-title {
    font-size: 14px;
    font-family: var(--font-family)
}

.panel-group .panel .panel-title>a {
    display: block;
    padding: 10px 15px
}

.panel-group .panel .panel-title>a:hover,
.panel-group .panel .panel-title>a:focus {
    color: inherit
}

.panel-group .panel .panel-title>a i {
    font-size: 18px;
    margin-right: 5px
}

.panel-group .panel .panel-title>a i.icon-collapsed {
    display: none
}

.panel-group .panel .panel-title>a i.right {
    float: right;
    margin-right: 0
}

.panel-group .panel .panel-title>a.collapsed .icon-expanded {
    display: none
}

.panel-group .panel .panel-title>a.collapsed .icon-collapsed {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    vertical-align: baseline
}

.pagination>li>a,
.pagination>li>span {
    color: var(--secondary-color)
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    background-color: #7ca4dd;
    border-color: #5c8ed4;
    color: #fff
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #7ca4dd;
    border-color: #5c8ed4;
    color: #fff
}

.pagination>.active>a,
.pagination>.active>span {
    background-color: #5c8ed4;
    border-color: #4880cf
}

.pagination.borderless>li>a,
.pagination.borderless>li>span {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border-color: transparent;
    margin: 0 1px
}

.pagination.borderless>li:first-child>a,
.pagination.borderless>li:first-child>span,
.pagination.borderless>li:last-child>a,
.pagination.borderless>li:last-child>span {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.pagination.borderless>li.active>a,
.pagination.borderless>li.active>span {
    color: var(--font-white);
    background-color: var(--secondary-color)
}

.pagination.borderless>li.active>a:hover,
.pagination.borderless>li.active>a:focus,
.pagination.borderless>li.active>span:hover,
.pagination.borderless>li.active>span:focus {
    color: var(--font-white);
    background-color: var(--secondary-color)
}

.pager li>a {
    border-color: var(--secondary-color)
}

.pager li>a:hover,
.pager li>a:focus {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--font-white)
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
    border-color: var(--font-ccc)
}

.bd-example-border-utils [class^=border] {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: .25rem;
    background-color: var(--font-xlight)
}

.blockquote {
    border-left: none;
    border: 1px solid var(--font-light);
    padding: 20px;
    font-size: 1.2em;
    line-height: 1.8
}

.blockquote.blockquote-primary {
    border-color: var(--primary-color)
}

.blockquote.blockquote-primary small {
    color: var(--primary-color)
}

.blockquote.blockquote-info {
    border-color: #3C89DA
}

.blockquote.blockquote-info small {
    color: #3C89DA
}

.blockquote.blockquote-danger {
    border-color: #de4848
}

.blockquote.blockquote-danger small {
    color: #de4848
}

.blockquote small {
    font-size: .93em;
    text-transform: uppercase
}

.list-group-item.active {
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

#jqstooltip {
    background-color: var(--font-white);
    border-color: var(--border-color);
    color: var(--font-color)
}

#jqstooltip .jqsfield {
    color: var(--font-color)
}

.ct-chart {
    position: relative
}

.ct-series .ct-line {
    stroke-width: 3px
}

.ct-series .ct-bar {
    stroke-width: 15px
}

.ct-series .ct-point {
    stroke-width: 10px;
    stroke-linecap: circle
}

.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-point {
    stroke: #6ebdd1
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-bar {
    fill: #6ebdd1
}

.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-point {
    stroke: #f9ab6c
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-bar {
    fill: #f9ab6c
}

.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-point {
    stroke: #afc979
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie,
.ct-series-c .ct-bar {
    fill: #afc979
}

.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-point {
    stroke: #AB7DF6
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie,
.ct-series-d .ct-bar {
    fill: #AB7DF6
}

.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-point {
    stroke: #5cc196
}

.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie,
.ct-series-e .ct-bar {
    fill: #5cc196
}

.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-point {
    stroke: #d17905
}

.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie,
.ct-series-f .ct-bar {
    fill: #d17905
}

.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-point {
    stroke: #453d3f
}

.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie,
.ct-series-g .ct-bar {
    fill: #453d3f
}

.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-point {
    stroke: #59922b
}

.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie,
.ct-series-h .ct-bar {
    fill: #59922b
}

.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-point {
    stroke: #0544d3
}

.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie,
.ct-series-i .ct-bar {
    fill: #0544d3
}

.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-point {
    stroke: #6b0392
}

.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie,
.ct-series-j .ct-bar {
    fill: #6b0392
}

.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-point {
    stroke: #f05b4f
}

.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie,
.ct-series-k .ct-bar {
    fill: #f05b4f
}

.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-point {
    stroke: #dda458
}

.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie,
.ct-series-l .ct-bar {
    fill: #dda458
}

.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-point {
    stroke: #eacf7d
}

.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie,
.ct-series-m .ct-bar {
    fill: #eacf7d
}

.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-point {
    stroke: #86797d
}

.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie,
.ct-series-n .ct-bar {
    fill: #86797d
}

.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-point {
    stroke: #b2c326
}

.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie,
.ct-series-o .ct-bar {
    fill: #b2c326
}

.chartist-tooltip {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    min-width: 3.5em;
    background: var(--font-color);
    color: var(--font-white)
}

.chartist-tooltip:before {
    margin-left: -8px;
    border-width: 8px;
    border-top-color: var(--font-color)
}

.ct-axis-title {
    fill: var(--font-ccc)
}

.ct-label {
    font-size: 14px;
    color: var(--font-color)
}

.ct-legend {
    position: relative;
    z-index: 10;
    text-align: right;
    padding: 0
}

.ct-legend li {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    padding-left: 18px;
    margin-bottom: 3px;
    margin-right: 20px;
    list-style-type: none
}

.ct-legend li:before {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 0;
    content: '';
    border: 3px solid transparent;
    border-radius: 2px
}

.ct-legend li.inactive:before {
    background: transparent
}

.ct-legend.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0
}

.ct-legend .ct-series-0:before {
    background-color: #6ebdd1;
    border-color: #6ebdd1
}

.ct-legend .ct-series-1:before {
    background-color: #f9ab6c;
    border-color: #f9ab6c
}

.ct-legend .ct-series-2:before {
    background-color: #afc979;
    border-color: #afc979
}

.ct-legend .ct-series-3:before {
    background-color: #AB7DF6;
    border-color: #AB7DF6
}

.ct-legend .ct-series-4:before {
    background-color: #5cc196;
    border-color: #5cc196
}

.ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905
}

.ct-legend .ct-series-6:before {
    background-color: #453d3f;
    border-color: #453d3f
}

.ct-legend .ct-series-7:before {
    background-color: #59922b;
    border-color: #59922b
}

.ct-legend .ct-series-8:before {
    background-color: #0544d3;
    border-color: #0544d3
}

.ct-legend .ct-series-9:before {
    background-color: #6b0392;
    border-color: #6b0392
}

.ct-legend .ct-series-10:before {
    background-color: #f05b4f;
    border-color: #f05b4f
}

.ct-legend .ct-series-11:before {
    background-color: #dda458;
    border-color: #dda458
}

.ct-legend .ct-series-12:before {
    background-color: #eacf7d;
    border-color: #eacf7d
}

.ct-legend .ct-series-13:before {
    background-color: #86797d;
    border-color: #86797d
}

.ct-legend .ct-series-14:before {
    background-color: #b2c326;
    border-color: #b2c326
}

.ct-grid {
    stroke: var(--border-color)
}

.user_statistics .ct-series .ct-point {
    stroke-width: 3px
}

.user_statistics .ct-series .ct-line {
    stroke-width: 1px
}

.user_statistics .ct-series-0:before {
    background-color: var(--white-color);
    border-color: #ff812d
}

.user_statistics .ct-series-1:before {
    background-color: var(--white-color);
    border-color: #42d182
}

.user_statistics .ct-series-2:before {
    background-color: var(--white-color);
    border-color: #0c85e0
}

.user_statistics .ct-series-3:before {
    background-color: var(--white-color);
    border-color: var(--dark-color)
}

.user_statistics .ct-series-a .ct-line,
.user_statistics .ct-series-a .ct-point {
    stroke: #ff812d
}

.user_statistics .ct-series-b .ct-line,
.user_statistics .ct-series-b .ct-point {
    stroke: #42d182
}

.user_statistics .ct-series-c .ct-line,
.user_statistics .ct-series-c .ct-point {
    stroke: #0c85e0
}

.user_statistics .ct-series-d .ct-line,
.user_statistics .ct-series-d .ct-point {
    stroke: var(--dark-color)
}

.c3-chart-arc path {
    stroke: var(--border-color)
}

.apexcharts-canvas line {
    stroke: var(--border-color)
}

.apexcharts-canvas text {
    fill: var(--font-color)
}

.apexcharts-canvas circle {
    stroke: var(--border-color)
}

.apexcharts-canvas .apexcharts-legend-text {
    color: var(--font-color) !important
}

.z500px-color {
    color: #0099e5 !important
}

.about-me-color {
    color: #00405d !important
}

.airbnb-color {
    color: #fd5c63 !important
}

.alphabet-color {
    color: #ed1c24 !important
}

.amazon-color {
    color: #f90 !important
}

.american-express-color {
    color: #002663 !important
}

.aol-color {
    color: #ff0b00 !important
}

.behance-color {
    color: #1769ff !important
}

.bing-color {
    color: #ffb900 !important
}

.bitbucket-color {
    color: #205081 !important
}

.bitly-color {
    color: #ee6123 !important
}

.blogger-color {
    color: #f57d00 !important
}

.booking-com-color {
    color: #003580 !important
}

.buffer-color {
    color: #168eea !important
}

.code-school-color {
    color: #616f67 !important
}

.codecademy-color {
    color: #f65a5b !important
}

.creative-market-color {
    color: #8ba753 !important
}

.delicious-color {
    color: #39f !important
}

.deviantart-color {
    color: #05cc47 !important
}

.digg-color {
    color: #005be2 !important
}

.disqus-color {
    color: #2e9fff !important
}

.django-color {
    color: #092e20 !important
}

.dribbble-color {
    color: #ea4c89 !important
}

.dropbox-color {
    color: #007ee5 !important
}

.drupal-color {
    color: #0077c0 !important
}

.elance-color {
    color: #0d69af !important
}

.envato-color {
    color: #82b541 !important
}

.etsy-color {
    color: #d5641c !important
}

.facebook-color {
    color: #3b5998 !important
}

.feedly-color {
    color: #2bb24c !important
}

.flattr-color {
    color: #f67c1a !important
}

.flickr-color {
    color: #0063dc !important
}

.flipboard-color {
    color: #e12828 !important
}

.flixster-color {
    color: #2971b2 !important
}

.foursquare-color {
    color: #0732a2 !important
}

.github-color {
    color: #333 !important
}

.google-color {
    color: #4285f4 !important
}

.google-plus-color {
    color: #dc4e41 !important
}

.instagram-color {
    color: #3f729b !important
}

.kickstarter-color {
    color: #2bde73 !important
}

.kik-color {
    color: #82bc23 !important
}

.lastfm-color {
    color: #d51007 !important
}

.line-color {
    color: #00c300 !important
}

.linkedin-color {
    color: #0077b5 !important
}

.mail-ru-color {
    color: #168de2 !important
}

.mailchimp-color {
    color: #2c9ab7 !important
}

.myspace-color {
    color: #000 !important
}

.netflix-color {
    color: #e50914 !important
}

.ning-color {
    color: #75af42 !important
}

.path-color {
    color: #ee3423 !important
}

.patreon-color {
    color: #e6461a !important
}

.paypal-color {
    color: #003087 !important
}

.photobucket-color {
    color: #0ea0db !important
}

.pinterest-color {
    color: #bd081c !important
}

.product-hunt-color {
    color: #da552f !important
}

.quora-color {
    color: #a82400 !important
}

.rdio-color {
    color: #007dc3 !important
}

.reddit-color {
    color: #ff4500 !important
}

.rss-color {
    color: #f26522 !important
}

.salesforce-color {
    color: #1798c1 !important
}

.scribd-color {
    color: #1a7bba !important
}

.shopify-color {
    color: #96bf48 !important
}

.skype-color {
    color: #00aff0 !important
}

.slack-color {
    color: #6ecadc !important
}

.slideshare-color {
    color: #0077b5 !important
}

.soundcloud-color {
    color: #f80 !important
}

.spotify-color {
    color: #2ebd59 !important
}

.squarespace-color {
    color: #222 !important
}

.stackoverflow-color {
    color: #fe7a15 !important
}

.stripe-color {
    color: #00afe1 !important
}

.stumbleupon-color {
    color: #eb4924 !important
}

.telegram-color {
    color: #08c !important
}

.tumblr-color {
    color: #35465c !important
}

.twitch-tv-color {
    color: #6441a5 !important
}

.twitter-color {
    color: #55acee !important
}

.vimeo-color {
    color: #162221 !important
}

.vine-color {
    color: #00b488 !important
}

.wechat-color {
    color: #7bb32e !important
}

.whatsapp-color {
    color: #43d854 !important
}

.wordpress-color {
    color: #21759b !important
}

.yahoo-color {
    color: #410093 !important
}

.youtube-color {
    color: #cd201f !important
}

.z500px-bg {
    background-color: #0099e5 !important
}

.z500px-bg:hover,
.z500px-bg:focus {
    background-color: #08c !important
}

.about-me-bg {
    background-color: #00405d !important
}

.about-me-bg:hover,
.about-me-bg:focus {
    background-color: #002e44 !important
}

.airbnb-bg {
    background-color: #fd5c63 !important
}

.airbnb-bg:hover,
.airbnb-bg:focus {
    background-color: #fd434b !important
}

.alphabet-bg {
    background-color: #ed1c24 !important
}

.alphabet-bg:hover,
.alphabet-bg:focus {
    background-color: #de1219 !important
}

.amazon-bg {
    background-color: #f90 !important
}

.amazon-bg:hover,
.amazon-bg:focus {
    background-color: #e68a00 !important
}

.american-express-bg {
    background-color: #002663 !important
}

.american-express-bg:hover,
.american-express-bg:focus {
    background-color: #001c4a !important
}

.aol-bg {
    background-color: #ff0b00 !important
}

.aol-bg:hover,
.aol-bg:focus {
    background-color: #e60a00 !important
}

.behance-bg {
    background-color: #1769ff !important
}

.behance-bg:hover,
.behance-bg:focus {
    background-color: #0059fd !important
}

.bing-bg {
    background-color: #ffb900 !important
}

.bing-bg:hover,
.bing-bg:focus {
    background-color: #e6a700 !important
}

.bitbucket-bg {
    background-color: #205081 !important
}

.bitbucket-bg:hover,
.bitbucket-bg:focus {
    background-color: #1b436d !important
}

.bitly-bg {
    background-color: #ee6123 !important
}

.bitly-bg:hover,
.bitly-bg:focus {
    background-color: #e65312 !important
}

.blogger-bg {
    background-color: #f57d00 !important
}

.blogger-bg:hover,
.blogger-bg:focus {
    background-color: #dc7000 !important
}

.booking-com-bg {
    background-color: #003580 !important
}

.booking-com-bg:hover,
.booking-com-bg:focus {
    background-color: #002a67 !important
}

.buffer-bg {
    background-color: #168eea !important
}

.buffer-bg:hover,
.buffer-bg:focus {
    background-color: #1380d3 !important
}

.code-school-bg {
    background-color: #616f67 !important
}

.code-school-bg:hover,
.code-school-bg:focus {
    background-color: #55615a !important
}

.codecademy-bg {
    background-color: #f65a5b !important
}

.codecademy-bg:hover,
.codecademy-bg:focus {
    background-color: #f54243 !important
}

.creative-market-bg {
    background-color: #8ba753 !important
}

.creative-market-bg:hover,
.creative-market-bg:focus {
    background-color: #7d964b !important
}

.delicious-bg {
    background-color: #39f !important
}

.delicious-bg:hover,
.delicious-bg:focus {
    background-color: #1a8cff !important
}

.deviantart-bg {
    background-color: #05cc47 !important
}

.deviantart-bg:hover,
.deviantart-bg:focus {
    background-color: #04b33e !important
}

.digg-bg {
    background-color: #005be2 !important
}

.digg-bg:hover,
.digg-bg:focus {
    background-color: #0051c9 !important
}

.disqus-bg {
    background-color: #2e9fff !important
}

.disqus-bg:hover,
.disqus-bg:focus {
    background-color: #1593ff !important
}

.django-bg {
    background-color: #092e20 !important
}

.django-bg:hover,
.django-bg:focus {
    background-color: #051911 !important
}

.dribbble-bg {
    background-color: #ea4c89 !important
}

.dribbble-bg:hover,
.dribbble-bg:focus {
    background-color: #e7357a !important
}

.dropbox-bg {
    background-color: #007ee5 !important
}

.dropbox-bg:hover,
.dropbox-bg:focus {
    background-color: #0070cc !important
}

.drupal-bg {
    background-color: #0077c0 !important
}

.drupal-bg:hover,
.drupal-bg:focus {
    background-color: #0067a7 !important
}

.elance-bg {
    background-color: #0d69af !important
}

.elance-bg:hover,
.elance-bg:focus {
    background-color: #0b5b97 !important
}

.envato-bg {
    background-color: #82b541 !important
}

.envato-bg:hover,
.envato-bg:focus {
    background-color: #75a23a !important
}

.etsy-bg {
    background-color: #d5641c !important
}

.etsy-bg:hover,
.etsy-bg:focus {
    background-color: #be5919 !important
}

.facebook-bg {
    background-color: #3b5998 !important
}

.facebook-bg:hover,
.facebook-bg:focus {
    background-color: #344e86 !important
}

.feedly-bg {
    background-color: #2bb24c !important
}

.feedly-bg:hover,
.feedly-bg:focus {
    background-color: #269d43 !important
}

.flattr-bg {
    background-color: #f67c1a !important
}

.flattr-bg:hover,
.flattr-bg:focus {
    background-color: #ed6f09 !important
}

.flickr-bg {
    background-color: #0063dc !important
}

.flickr-bg:hover,
.flickr-bg:focus {
    background-color: #0058c3 !important
}

.flipboard-bg {
    background-color: #e12828 !important
}

.flipboard-bg:hover,
.flipboard-bg:focus {
    background-color: #d21d1d !important
}

.flixster-bg {
    background-color: #2971b2 !important
}

.flixster-bg:hover,
.flixster-bg:focus {
    background-color: #24649d !important
}

.foursquare-bg {
    background-color: #0732a2 !important
}

.foursquare-bg:hover,
.foursquare-bg:focus {
    background-color: #062a8a !important
}

.github-bg {
    background-color: #333 !important
}

.github-bg:hover,
.github-bg:focus {
    background-color: #262626 !important
}

.google-bg {
    background-color: #4285f4 !important
}

.google-bg:hover,
.google-bg:focus {
    background-color: #2a75f3 !important
}

.google-plus-bg {
    background-color: #dc4e41 !important
}

.google-plus-bg:hover,
.google-plus-bg:focus {
    background-color: #d83a2b !important
}

.instagram-bg {
    background-color: #3f729b !important
}

.instagram-bg:hover,
.instagram-bg:focus {
    background-color: #386589 !important
}

.kickstarter-bg {
    background-color: #2bde73 !important
}

.kickstarter-bg:hover,
.kickstarter-bg:focus {
    background-color: #20cf67 !important
}

.kik-bg {
    background-color: #82bc23 !important
}

.kik-bg:hover,
.kik-bg:focus {
    background-color: #73a71f !important
}

.lastfm-bg {
    background-color: #d51007 !important
}

.lastfm-bg:hover,
.lastfm-bg:focus {
    background-color: #bc0e06 !important
}

.line-bg {
    background-color: #00c300 !important
}

.line-bg:hover,
.line-bg:focus {
    background-color: #0a0 !important
}

.linkedin-bg {
    background-color: #0077b5 !important
}

.linkedin-bg:hover,
.linkedin-bg:focus {
    background-color: #00669c !important
}

.mail-ru-bg {
    background-color: #168de2 !important
}

.mail-ru-bg:hover,
.mail-ru-bg:focus {
    background-color: #147fcb !important
}

.mailchimp-bg {
    background-color: #2c9ab7 !important
}

.mailchimp-bg:hover,
.mailchimp-bg:focus {
    background-color: #2789a2 !important
}

.myspace-bg {
    background-color: #000 !important
}

.myspace-bg:hover,
.myspace-bg:focus {
    background-color: #000 !important
}

.netflix-bg {
    background-color: #e50914 !important
}

.netflix-bg:hover,
.netflix-bg:focus {
    background-color: #cc0812 !important
}

.ning-bg {
    background-color: #75af42 !important
}

.ning-bg:hover,
.ning-bg:focus {
    background-color: #699c3b !important
}

.path-bg {
    background-color: #ee3423 !important
}

.path-bg:hover,
.path-bg:focus {
    background-color: #e62412 !important
}

.patreon-bg {
    background-color: #e6461a !important
}

.patreon-bg:hover,
.patreon-bg:focus {
    background-color: #d03f17 !important
}

.paypal-bg {
    background-color: #003087 !important
}

.paypal-bg:hover,
.paypal-bg:focus {
    background-color: #00276e !important
}

.photobucket-bg {
    background-color: #0ea0db !important
}

.photobucket-bg:hover,
.photobucket-bg:focus {
    background-color: #0c8ec3 !important
}

.pinterest-bg {
    background-color: #bd081c !important
}

.pinterest-bg:hover,
.pinterest-bg:focus {
    background-color: #a50718 !important
}

.product-hunt-bg {
    background-color: #da552f !important
}

.product-hunt-bg:hover,
.product-hunt-bg:focus {
    background-color: #cb4924 !important
}

.quora-bg {
    background-color: #a82400 !important
}

.quora-bg:hover,
.quora-bg:focus {
    background-color: #8f1f00 !important
}

.rdio-bg {
    background-color: #007dc3 !important
}

.rdio-bg:hover,
.rdio-bg:focus {
    background-color: #006daa !important
}

.reddit-bg {
    background-color: #ff4500 !important
}

.reddit-bg:hover,
.reddit-bg:focus {
    background-color: #e63e00 !important
}

.rss-bg {
    background-color: #f26522 !important
}

.rss-bg:hover,
.rss-bg:focus {
    background-color: #ed560e !important
}

.salesforce-bg {
    background-color: #1798c1 !important
}

.salesforce-bg:hover,
.salesforce-bg:focus {
    background-color: #1486aa !important
}

.scribd-bg {
    background-color: #1a7bba !important
}

.scribd-bg:hover,
.scribd-bg:focus {
    background-color: #176ca4 !important
}

.shopify-bg {
    background-color: #96bf48 !important
}

.shopify-bg:hover,
.shopify-bg:focus {
    background-color: #89b03e !important
}

.skype-bg {
    background-color: #00aff0 !important
}

.skype-bg:hover,
.skype-bg:focus {
    background-color: #009cd7 !important
}

.slack-bg {
    background-color: #6ecadc !important
}

.slack-bg:hover,
.slack-bg:focus {
    background-color: #59c2d7 !important
}

.slideshare-bg {
    background-color: #0077b5 !important
}

.slideshare-bg:hover,
.slideshare-bg:focus {
    background-color: #00669c !important
}

.soundcloud-bg {
    background-color: #f80 !important
}

.soundcloud-bg:hover,
.soundcloud-bg:focus {
    background-color: #e67a00 !important
}

.spotify-bg {
    background-color: #2ebd59 !important
}

.spotify-bg:hover,
.spotify-bg:focus {
    background-color: #29a84f !important
}

.squarespace-bg {
    background-color: #222 !important
}

.squarespace-bg:hover,
.squarespace-bg:focus {
    background-color: #151515 !important
}

.stackoverflow-bg {
    background-color: #fe7a15 !important
}

.stackoverflow-bg:hover,
.stackoverflow-bg:focus {
    background-color: #f86c01 !important
}

.stripe-bg {
    background-color: #00afe1 !important
}

.stripe-bg:hover,
.stripe-bg:focus {
    background-color: #009bc8 !important
}

.stumbleupon-bg {
    background-color: #eb4924 !important
}

.stumbleupon-bg:hover,
.stumbleupon-bg:focus {
    background-color: #e13b15 !important
}

.telegram-bg {
    background-color: #08c !important
}

.telegram-bg:hover,
.telegram-bg:focus {
    background-color: #0077b3 !important
}

.tumblr-bg {
    background-color: #35465c !important
}

.tumblr-bg:hover,
.tumblr-bg:focus {
    background-color: #2c3a4c !important
}

.twitch-tv-bg {
    background-color: #6441a5 !important
}

.twitch-tv-bg:hover,
.twitch-tv-bg:focus {
    background-color: #593a93 !important
}

.twitter-bg {
    background-color: #55acee !important
}

.twitter-bg:hover,
.twitter-bg:focus {
    background-color: #3ea1ec !important
}

.vimeo-bg {
    background-color: #162221 !important
}

.vimeo-bg:hover,
.vimeo-bg:focus {
    background-color: #0c1312 !important
}

.vine-bg {
    background-color: #00b488 !important
}

.vine-bg:hover,
.vine-bg:focus {
    background-color: #009b75 !important
}

.wechat-bg {
    background-color: #7bb32e !important
}

.wechat-bg:hover,
.wechat-bg:focus {
    background-color: #6d9f29 !important
}

.whatsapp-bg {
    background-color: #43d854 !important
}

.whatsapp-bg:hover,
.whatsapp-bg:focus {
    background-color: #2ed441 !important
}

.wordpress-bg {
    background-color: #21759b !important
}

.wordpress-bg:hover,
.wordpress-bg:focus {
    background-color: #1d6586 !important
}

.yahoo-bg {
    background-color: #410093 !important
}

.yahoo-bg:hover,
.yahoo-bg:focus {
    background-color: #36007a !important
}

.youtube-bg {
    background-color: #cd201f !important
}

.youtube-bg:hover,
.youtube-bg:focus {
    background-color: #b71d1c !important
}

ul.list-justify>li {
    margin-bottom: 5px
}

ul.list-justify>li span,
ul.list-justify>li strong {
    float: right
}

ul.list-justify.large-number>li {
    margin-bottom: 15px
}

ul.list-justify.large-number span {
    font-size: 32px;
    line-height: 1
}

.text-primary {
    color: var(--primary-color)
}

.text-info {
    color: #3C89DA
}

.text-success {
    color: #22af46
}

.text-warning {
    color: #f3ad06
}

.text-danger {
    color: #de4848
}

.text-muted {
    color: var(--text-muted) !important
}

.text-larger {
    font-size: 1.2em
}

.text-large {
    font-size: 1.53em
}

.m-l--125 {
    margin-left: -125px
}

.m-t--125 {
    margin-top: -125px
}

.m-r--125 {
    margin-right: -125px
}

.m-b--125 {
    margin-bottom: -125px
}

.m-l--120 {
    margin-left: -120px
}

.m-t--120 {
    margin-top: -120px
}

.m-r--120 {
    margin-right: -120px
}

.m-b--120 {
    margin-bottom: -120px
}

.m-l--115 {
    margin-left: -115px
}

.m-t--115 {
    margin-top: -115px
}

.m-r--115 {
    margin-right: -115px
}

.m-b--115 {
    margin-bottom: -115px
}

.m-l--110 {
    margin-left: -110px
}

.m-t--110 {
    margin-top: -110px
}

.m-r--110 {
    margin-right: -110px
}

.m-b--110 {
    margin-bottom: -110px
}

.m-l--105 {
    margin-left: -105px
}

.m-t--105 {
    margin-top: -105px
}

.m-r--105 {
    margin-right: -105px
}

.m-b--105 {
    margin-bottom: -105px
}

.m-l--100 {
    margin-left: -100px
}

.m-t--100 {
    margin-top: -100px
}

.m-r--100 {
    margin-right: -100px
}

.m-b--100 {
    margin-bottom: -100px
}

.m-l--95 {
    margin-left: -95px
}

.m-t--95 {
    margin-top: -95px
}

.m-r--95 {
    margin-right: -95px
}

.m-b--95 {
    margin-bottom: -95px
}

.m-l--90 {
    margin-left: -90px
}

.m-t--90 {
    margin-top: -90px
}

.m-r--90 {
    margin-right: -90px
}

.m-b--90 {
    margin-bottom: -90px
}

.m-l--85 {
    margin-left: -85px
}

.m-t--85 {
    margin-top: -85px
}

.m-r--85 {
    margin-right: -85px
}

.m-b--85 {
    margin-bottom: -85px
}

.m-l--80 {
    margin-left: -80px
}

.m-t--80 {
    margin-top: -80px
}

.m-r--80 {
    margin-right: -80px
}

.m-b--80 {
    margin-bottom: -80px
}

.m-l--75 {
    margin-left: -75px
}

.m-t--75 {
    margin-top: -75px
}

.m-r--75 {
    margin-right: -75px
}

.m-b--75 {
    margin-bottom: -75px
}

.m-l--70 {
    margin-left: -70px
}

.m-t--70 {
    margin-top: -70px
}

.m-r--70 {
    margin-right: -70px
}

.m-b--70 {
    margin-bottom: -70px
}

.m-l--65 {
    margin-left: -65px
}

.m-t--65 {
    margin-top: -65px
}

.m-r--65 {
    margin-right: -65px
}

.m-b--65 {
    margin-bottom: -65px
}

.m-l--60 {
    margin-left: -60px
}

.m-t--60 {
    margin-top: -60px
}

.m-r--60 {
    margin-right: -60px
}

.m-b--60 {
    margin-bottom: -60px
}

.m-l--55 {
    margin-left: -55px
}

.m-t--55 {
    margin-top: -55px
}

.m-r--55 {
    margin-right: -55px
}

.m-b--55 {
    margin-bottom: -55px
}

.m-l--50 {
    margin-left: -50px
}

.m-t--50 {
    margin-top: -50px
}

.m-r--50 {
    margin-right: -50px
}

.m-b--50 {
    margin-bottom: -50px
}

.m-l--45 {
    margin-left: -45px
}

.m-t--45 {
    margin-top: -45px
}

.m-r--45 {
    margin-right: -45px
}

.m-b--45 {
    margin-bottom: -45px
}

.m-l--40 {
    margin-left: -40px
}

.m-t--40 {
    margin-top: -40px
}

.m-r--40 {
    margin-right: -40px
}

.m-b--40 {
    margin-bottom: -40px
}

.m-l--35 {
    margin-left: -35px
}

.m-t--35 {
    margin-top: -35px
}

.m-r--35 {
    margin-right: -35px
}

.m-b--35 {
    margin-bottom: -35px
}

.m-l--30 {
    margin-left: -30px
}

.m-t--30 {
    margin-top: -30px
}

.m-r--30 {
    margin-right: -30px
}

.m-b--30 {
    margin-bottom: -30px
}

.m-l--25 {
    margin-left: -25px
}

.m-t--25 {
    margin-top: -25px
}

.m-r--25 {
    margin-right: -25px
}

.m-b--25 {
    margin-bottom: -25px
}

.m-l--20 {
    margin-left: -20px
}

.m-t--20 {
    margin-top: -20px
}

.m-r--20 {
    margin-right: -20px
}

.m-b--20 {
    margin-bottom: -20px
}

.m-l--15 {
    margin-left: -15px
}

.m-t--15 {
    margin-top: -15px
}

.m-r--15 {
    margin-right: -15px
}

.m-b--15 {
    margin-bottom: -15px
}

.m-l--10 {
    margin-left: -10px
}

.m-t--10 {
    margin-top: -10px
}

.m-r--10 {
    margin-right: -10px
}

.m-b--10 {
    margin-bottom: -10px
}

.m-l--5 {
    margin-left: -5px
}

.m-t--5 {
    margin-top: -5px
}

.m-r--5 {
    margin-right: -5px
}

.m-b--5 {
    margin-bottom: -5px
}

.m-l-0 {
    margin-left: 0px
}

.m-t-0,
.tabcontrol>.steps>ul>li.current,
.weather2 .days-list .day h5,
.weather .city,
.weather .city h3,
.page-calendar .event-name h4 {
    margin-top: 0px
}

.m-r-0,
.c_list .fancy-checkbox {
    margin-right: 0px
}

.m-b-0,
.navbar-nav ul.notifications li>a .media,
.navbar-nav ul.notifications li .text,
ul.choose-skin,
.auth-box .lead,
#toast-container .toast,
.weather .city h3,
.weather6 p,
.basic-list,
.c_list .fancy-checkbox,
.c_list .c_name,
.c_list address,
.social_media_table .table,
.feeds_widget,
.page-calendar .event-name h6,
.page-calendar .event-name h4,
.page-calendar .event-name p,
.page-calendar .event-name address,
.folder h6,
.pricing,
.pricing h3 {
    margin-bottom: 0px
}

.m-l-5,
.badge,
.btn-toolbar>.btn-group,
.wizard>.actions a,
.pricing3 .pricing-option .price b {
    margin-left: 5px
}

.m-t-5,
.card .header h2 small,
.user-account .dropdown,
.auth-box .lead,
.wizard>.actions,
.setting-list li,
.team-info,
.page-calendar .event-name address {
    margin-top: 5px
}

.m-r-5,
.badge,
.nav-tabs-new>li>a,
.nav-tabs-new2>li>a,
.new_timeline .bullet,
.b4gallery .nav-item,
.page-calendar .event-name address i {
    margin-right: 5px
}

.m-b-5,
.auth-box .btn-signin-social,
.weather .days li img {
    margin-bottom: 5px
}

.m-l-10,
.c_list .c_name {
    margin-left: 10px
}

.m-t-10,
.page-loader-wrapper p,
.weather3 .top .wrapper .temp .deg,
.member-card .social-links {
    margin-top: 10px
}

.m-r-10,
.user-account .user-photo,
.user-account .dropdown .dropdown-menu a i,
.right_chat .media .media-object,
.testimonial4 .carousel-info img {
    margin-right: 10px
}

.m-b-10,
.weather4 i,
.pricing3 .pricing-option .price {
    margin-bottom: 10px
}

.m-l-15 {
    margin-left: 15px
}

.m-t-15,
.question li.menu-heading {
    margin-top: 15px
}

.m-r-15,
.navbar-nav,
.new_timeline .header .color-overlay .day-number {
    margin-right: 15px
}

.m-b-15,
.w_social3 .card .fa {
    margin-bottom: 15px
}

.m-l-20,
.media.mright .media-right,
.chat-widget li.left .chat-info,
.invoice1 .info {
    margin-left: 20px
}

.m-t-20,
.auth-box .form-auth-small .btn,
.auth-box .form-auth-small .bottom,
.block-header,
.weather3 .top .wrapper .heading,
.weather3 .top .wrapper .location,
.stats-report,
.pricing3 .pricing-option .price {
    margin-top: 20px
}

.m-r-20,
.navbar-nav ul.notifications li>a .media .media-left,
.media.mleft .media-left,
.bs-example .media .media-left,
.chat-widget li.right .chat-info {
    margin-right: 20px
}

.m-b-20,
.block-header,
.media,
.chat-widget li,
.weather .city,
.chart.easy-pie-chart-1,
.profilepage_2 .profile-header .profile-image {
    margin-bottom: 20px
}

.m-l-25 {
    margin-left: 25px
}

.m-t-25 {
    margin-top: 25px
}

.m-r-25 {
    margin-right: 25px
}

.m-b-25,
.pricing2 .pricing-plan .pricing-img {
    margin-bottom: 25px
}

.m-l-30 {
    margin-left: 30px
}

.m-t-30 {
    margin-top: 30px
}

.m-r-30 {
    margin-right: 30px
}

.m-b-30,
.auth-box .top,
.testimonial2,
.testimonial3,
.testimonial4 {
    margin-bottom: 30px
}

.m-l-35 {
    margin-left: 35px
}

.m-t-35 {
    margin-top: 35px
}

.m-r-35 {
    margin-right: 35px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-l-40 {
    margin-left: 40px
}

.m-t-40 {
    margin-top: 40px
}

.m-r-40 {
    margin-right: 40px
}

.m-b-40 {
    margin-bottom: 40px
}

.m-l-45 {
    margin-left: 45px
}

.m-t-45 {
    margin-top: 45px
}

.m-r-45 {
    margin-right: 45px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-l-50 {
    margin-left: 50px
}

.m-t-50 {
    margin-top: 50px
}

.m-r-50 {
    margin-right: 50px
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-55 {
    margin-left: 55px
}

.m-t-55 {
    margin-top: 55px
}

.m-r-55 {
    margin-right: 55px
}

.m-b-55 {
    margin-bottom: 55px
}

.m-l-60 {
    margin-left: 60px
}

.m-t-60 {
    margin-top: 60px
}

.m-r-60 {
    margin-right: 60px
}

.m-b-60 {
    margin-bottom: 60px
}

.m-l-65 {
    margin-left: 65px
}

.m-t-65 {
    margin-top: 65px
}

.m-r-65 {
    margin-right: 65px
}

.m-b-65 {
    margin-bottom: 65px
}

.m-l-70 {
    margin-left: 70px
}

.m-t-70 {
    margin-top: 70px
}

.m-r-70 {
    margin-right: 70px
}

.m-b-70 {
    margin-bottom: 70px
}

.m-l-75 {
    margin-left: 75px
}

.m-t-75 {
    margin-top: 75px
}

.m-r-75 {
    margin-right: 75px
}

.m-b-75 {
    margin-bottom: 75px
}

.m-l-80 {
    margin-left: 80px
}

.m-t-80 {
    margin-top: 80px
}

.m-r-80 {
    margin-right: 80px
}

.m-b-80 {
    margin-bottom: 80px
}

.m-l-85 {
    margin-left: 85px
}

.m-t-85 {
    margin-top: 85px
}

.m-r-85 {
    margin-right: 85px
}

.m-b-85 {
    margin-bottom: 85px
}

.m-l-90 {
    margin-left: 90px
}

.m-t-90 {
    margin-top: 90px
}

.m-r-90 {
    margin-right: 90px
}

.m-b-90 {
    margin-bottom: 90px
}

.m-l-95 {
    margin-left: 95px
}

.m-t-95 {
    margin-top: 95px
}

.m-r-95 {
    margin-right: 95px
}

.m-b-95 {
    margin-bottom: 95px
}

.m-l-100 {
    margin-left: 100px
}

.m-t-100 {
    margin-top: 100px
}

.m-r-100 {
    margin-right: 100px
}

.m-b-100 {
    margin-bottom: 100px
}

.m-l-105 {
    margin-left: 105px
}

.m-t-105 {
    margin-top: 105px
}

.m-r-105 {
    margin-right: 105px
}

.m-b-105 {
    margin-bottom: 105px
}

.m-l-110 {
    margin-left: 110px
}

.m-t-110 {
    margin-top: 110px
}

.m-r-110 {
    margin-right: 110px
}

.m-b-110 {
    margin-bottom: 110px
}

.m-l-115 {
    margin-left: 115px
}

.m-t-115 {
    margin-top: 115px
}

.m-r-115 {
    margin-right: 115px
}

.m-b-115 {
    margin-bottom: 115px
}

.m-l-120 {
    margin-left: 120px
}

.m-t-120 {
    margin-top: 120px
}

.m-r-120 {
    margin-right: 120px
}

.m-b-120 {
    margin-bottom: 120px
}

.m-l-125 {
    margin-left: 125px
}

.m-t-125 {
    margin-top: 125px
}

.m-r-125 {
    margin-right: 125px
}

.m-b-125 {
    margin-bottom: 125px
}

.margin-0,
.card .header .header-dropdown,
.card .header h2,
.navbar-nav,
.nav-tabs-new>li>a,
.sidebar-nav ul,
.block-header .breadcrumb,
.media .media-body .media-heading,
.wizard ul,
.tabcontrol ul,
.tabcontrol>.steps>ul>li>a,
.weather2 .city-selected .temp h2,
.weather2 .days-list,
.weather3 .bottom .forecast,
.weather4 p,
.stats-report .stat-item h5,
.new_timeline>ul,
.new_timeline h3,
.new_timeline h4,
.c_list tr td .btn,
.project_report h6,
.project_report .team-info,
.w_social2 .pw_content .pw_header h6,
.w_user .wid-u-info h5,
.feeds_widget li .feeds-body .title,
.timeline-item h5,
.fc-overlay.right,
.fc .fc-toolbar,
.fc .fc-toolbar h2 {
    margin: 0 !important
}

.p-l-0,
.pricing,
.invoice .nav-tabs {
    padding-left: 0px
}

.p-t-0,
.basic-list li:first-child {
    padding-top: 0px
}

.p-r-0,
.invoice .nav-tabs {
    padding-right: 0px
}

.p-b-0,
.number-chart .body,
.basic-list li:last-child {
    padding-bottom: 0px
}

.p-l-5 {
    padding-left: 5px
}

.p-t-5,
.fc .fc-toolbar h2 {
    padding-top: 5px
}

.p-r-5,
.wizard.vertical>.steps {
    padding-right: 5px
}

.p-b-5 {
    padding-bottom: 5px
}

.p-l-10 {
    padding-left: 10px
}

.p-t-10,
.fc .fc-toolbar {
    padding-top: 10px
}

.p-r-10 {
    padding-right: 10px
}

.p-b-10,
.fc .fc-toolbar {
    padding-bottom: 10px
}

.p-l-15,
.question li a {
    padding-left: 15px
}

.p-t-15,
.basic-list li {
    padding-top: 15px
}

.p-r-15,
.stats-report .stat-item {
    padding-right: 15px
}

.p-b-15,
.basic-list li {
    padding-bottom: 15px
}

.p-l-20 {
    padding-left: 20px
}

.p-t-20,
.tabcontrol>.content,
.weather3 .bottom .forecast {
    padding-top: 20px
}

.p-r-20 {
    padding-right: 20px
}

.p-b-20 {
    padding-bottom: 20px
}

.p-l-25 {
    padding-left: 25px
}

.p-t-25 {
    padding-top: 25px
}

.p-r-25 {
    padding-right: 25px
}

.p-b-25 {
    padding-bottom: 25px
}

.p-l-30 {
    padding-left: 30px
}

.p-t-30 {
    padding-top: 30px
}

.p-r-30 {
    padding-right: 30px
}

.p-b-30 {
    padding-bottom: 30px
}

.p-l-35 {
    padding-left: 35px
}

.p-t-35 {
    padding-top: 35px
}

.p-r-35 {
    padding-right: 35px
}

.p-b-35 {
    padding-bottom: 35px
}

.p-l-40 {
    padding-left: 40px
}

.p-t-40 {
    padding-top: 40px
}

.p-r-40 {
    padding-right: 40px
}

.p-b-40 {
    padding-bottom: 40px
}

.p-l-45 {
    padding-left: 45px
}

.p-t-45 {
    padding-top: 45px
}

.p-r-45 {
    padding-right: 45px
}

.p-b-45 {
    padding-bottom: 45px
}

.p-l-50 {
    padding-left: 50px
}

.p-t-50 {
    padding-top: 50px
}

.p-r-50 {
    padding-right: 50px
}

.p-b-50 {
    padding-bottom: 50px
}

.p-l-55 {
    padding-left: 55px
}

.p-t-55 {
    padding-top: 55px
}

.p-r-55 {
    padding-right: 55px
}

.p-b-55 {
    padding-bottom: 55px
}

.p-l-60 {
    padding-left: 60px
}

.p-t-60 {
    padding-top: 60px
}

.p-r-60 {
    padding-right: 60px
}

.p-b-60 {
    padding-bottom: 60px
}

.p-l-65 {
    padding-left: 65px
}

.p-t-65 {
    padding-top: 65px
}

.p-r-65 {
    padding-right: 65px
}

.p-b-65 {
    padding-bottom: 65px
}

.p-l-70 {
    padding-left: 70px
}

.p-t-70 {
    padding-top: 70px
}

.p-r-70 {
    padding-right: 70px
}

.p-b-70 {
    padding-bottom: 70px
}

.p-l-75 {
    padding-left: 75px
}

.p-t-75 {
    padding-top: 75px
}

.p-r-75 {
    padding-right: 75px
}

.p-b-75 {
    padding-bottom: 75px
}

.p-l-80 {
    padding-left: 80px
}

.p-t-80 {
    padding-top: 80px
}

.p-r-80 {
    padding-right: 80px
}

.p-b-80 {
    padding-bottom: 80px
}

.p-l-85 {
    padding-left: 85px
}

.p-t-85 {
    padding-top: 85px
}

.p-r-85 {
    padding-right: 85px
}

.p-b-85 {
    padding-bottom: 85px
}

.p-l-90 {
    padding-left: 90px
}

.p-t-90 {
    padding-top: 90px
}

.p-r-90 {
    padding-right: 90px
}

.p-b-90 {
    padding-bottom: 90px
}

.p-l-95 {
    padding-left: 95px
}

.p-t-95 {
    padding-top: 95px
}

.p-r-95 {
    padding-right: 95px
}

.p-b-95 {
    padding-bottom: 95px
}

.p-l-100 {
    padding-left: 100px
}

.p-t-100 {
    padding-top: 100px
}

.p-r-100 {
    padding-right: 100px
}

.p-b-100 {
    padding-bottom: 100px
}

.p-l-105 {
    padding-left: 105px
}

.p-t-105 {
    padding-top: 105px
}

.p-r-105 {
    padding-right: 105px
}

.p-b-105 {
    padding-bottom: 105px
}

.p-l-110 {
    padding-left: 110px
}

.p-t-110 {
    padding-top: 110px
}

.p-r-110 {
    padding-right: 110px
}

.p-b-110 {
    padding-bottom: 110px
}

.p-l-115 {
    padding-left: 115px
}

.p-t-115 {
    padding-top: 115px
}

.p-r-115 {
    padding-right: 115px
}

.p-b-115 {
    padding-bottom: 115px
}

.p-l-120 {
    padding-left: 120px
}

.p-t-120 {
    padding-top: 120px
}

.p-r-120 {
    padding-right: 120px
}

.p-b-120 {
    padding-bottom: 120px
}

.p-l-125 {
    padding-left: 125px
}

.p-t-125 {
    padding-top: 125px
}

.p-r-125 {
    padding-right: 125px
}

.p-b-125 {
    padding-bottom: 125px
}

.padding-0,
.card .header .header-dropdown,
.sidebar-nav ul,
.search-form,
.search-form .btn,
.block-header .breadcrumb,
.accordion .card-header,
.wizard ul,
.tabcontrol ul,
.wizard ul>li,
.wizard .tabcontrol ul>li,
.tabcontrol>.steps>ul>li:hover,
.chat-widget,
.weather3 .bottom .forecast,
.new_timeline .header,
.right_chat .media .status,
.profilepage_1 .tab-content,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header,
.file_manager .file,
.pricing2 .pricing-plan .pricing-features {
    padding: 0 !important
}

.font-6 {
    font-size: 6px
}

.font-7 {
    font-size: 7px
}

.font-8 {
    font-size: 8px
}

.font-9 {
    font-size: 9px
}

.font-10 {
    font-size: 10px
}

.font-11 {
    font-size: 11px
}

.font-12 {
    font-size: 12px
}

.font-13 {
    font-size: 13px
}

.font-14 {
    font-size: 14px
}

.font-15 {
    font-size: 15px
}

.font-16 {
    font-size: 16px
}

.font-17 {
    font-size: 17px
}

.font-18 {
    font-size: 18px
}

.font-19 {
    font-size: 19px
}

.font-20 {
    font-size: 20px
}

.font-21 {
    font-size: 21px
}

.font-22 {
    font-size: 22px
}

.font-23 {
    font-size: 23px
}

.font-24 {
    font-size: 24px
}

.font-25 {
    font-size: 25px
}

.font-26 {
    font-size: 26px
}

.font-27 {
    font-size: 27px
}

.font-28 {
    font-size: 28px
}

.font-29 {
    font-size: 29px
}

.font-30 {
    font-size: 30px
}

.font-31 {
    font-size: 31px
}

.font-32 {
    font-size: 32px
}

.font-33 {
    font-size: 33px
}

.font-34 {
    font-size: 34px
}

.font-35 {
    font-size: 35px
}

.font-36 {
    font-size: 36px
}

.font-37 {
    font-size: 37px
}

.font-38 {
    font-size: 38px
}

.font-39 {
    font-size: 39px
}

.font-40 {
    font-size: 40px
}

.font-41 {
    font-size: 41px
}

.font-42 {
    font-size: 42px
}

.font-43 {
    font-size: 43px
}

.font-44 {
    font-size: 44px
}

.font-45 {
    font-size: 45px
}

.font-46 {
    font-size: 46px
}

.font-47 {
    font-size: 47px
}

.font-48 {
    font-size: 48px
}

.font-49 {
    font-size: 49px
}

.font-50 {
    font-size: 50px
}

.align-left,
.user-account,
.user-account .dropdown,
.accordion .card-header .btn,
.chat-widget li.left .chat-info {
    text-align: left
}

.align-center,
.auth-box .form-auth-small .bottom,
.separator-linethrough,
.page-loader-wrapper,
.info-box-2 .chart,
.weather2 .days-list .day,
.weather .days li,
.weather3 .top,
.top_widget .icon,
.top_counter .icon,
.chart.easy-pie-chart-1,
.chart.easy-pie-chart-1>span>img,
.c_list address i,
.social_icon,
.profile_state,
.testimonials .body,
.member-card,
.w_social3 .card,
.profilepage_2 .profile-header,
.file_manager .file .icon,
.pricing,
.pricing2,
.pricing3 {
    text-align: center
}

.align-right,
.media.mright .media-body,
.wizard>.actions,
.chat-widget li.right,
.chat-widget li.right .chat-info {
    text-align: right
}

.align-justify {
    text-align: justify
}

.padding-25 {
    padding: 25px !important
}

hr {
    border-color: var(--border-color)
}

.inlineblock,
.card,
.card .header .header-dropdown li,
.navbar-fixed-top .navbar-right .navbar-nav,
.navbar-fixed-top .navbar-right .navbar-nav>li,
.user-account .dropdown,
ul.choose-skin li div,
.wizard>.actions ul,
.tabcontrol>.steps>ul>li>a,
.tabcontrol>.content,
.chat-widget li.right .chat-info,
.chat-widget li .chat-info,
.info-box-2 .icon,
.info-box-2 .content,
.weather .days li,
.weather3 .top .wrapper .temp .temp-value,
.weather3 .top .wrapper .temp .deg,
.stats-report .stat-item,
.new_timeline .header .color-overlay .day-number,
.new_timeline .header .color-overlay .date-right,
.new_timeline li div,
.team-info li,
.chart.easy-pie-chart-1,
.c_list .c_name,
.right_chat .media .message,
.right_chat .media .status,
.dt-bootstrap4 .dt-buttons,
.dt-bootstrap4 .dataTables_info,
.dt-bootstrap4 .dt-buttons+.dataTables_filter,
.dt-bootstrap4 .dt-buttons+.dataTables_paginate,
.dt-bootstrap4 .dataTables_info+.dataTables_filter,
.dt-bootstrap4 .dataTables_info+.dataTables_paginate,
.social_icon,
.member-card .social-links li {
    display: inline-block
}

.displayblock,
.card .header .header-dropdown li a,
.card .header h2 small,
.navbar-nav ul.notifications li>a,
.user-account .user-name,
.user-account .dropdown .dropdown-menu a,
.sidebar-nav .metismenu a,
.auth-box .helper-text,
.auth-box .btn-signin-social,
.separator-linethrough:after,
.wizard,
.tabcontrol,
.wizard ul>li,
.wizard .tabcontrol ul>li,
.wizard>.steps,
.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active,
.wizard>.content,
.wizard>.actions,
.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active,
.tabcontrol>.steps,
.chat-widget li .message,
.info-box-2 .chart,
.weather2 .city-selected .temp h2,
.weather .days li .degrees,
.weather3 .top::after,
.weather3 .bottom .forecast li,
.basic-list li,
.right_chat .media .name,
.social_media_table .list-name,
.testimonial4 blockquote,
.testimonial4 blockquote:before,
.testimonial4 blockquote:after,
.member-card .text-pink,
.w_social2 .pw_img:before,
.w_social2 .pw_content .pw_meta small,
.w_social2 .pw_content .pw_meta span,
.w_profile .job_post,
.feeds_widget li .feeds-body,
.feeds_widget li .feeds-body>small,
.timeline-item:before,
.timeline-item:after,
.page-calendar .event-name h4 span,
.page-calendar .event-name p,
.fc .fc-view-container .fc-event:focus .fc-overlay,
.fc .fc-view-container .fc-event:hover .fc-overlay,
.fc .fc-view-container .fc-event:active .fc-overlay,
.file_manager .file a:hover .hover,
.file_manager .file .file-name small,
.pricing3 .pricing-option .price,
.invoice1 .info {
    display: block
}

.displaynone {
    display: none
}

.overflowhidden,
.member-card {
    overflow: hidden
}

.z-index {
    z-index: 9999
}

.p-10 {
    padding: 10px !important
}

.l-green {
    background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
}

.l-pink {
    background: linear-gradient(45deg, pink, #fbc1cb) !important
}

.l-turquoise {
    background: linear-gradient(45deg, #00ced1, #08e5e8) !important
}

.l-cyan {
    background: linear-gradient(45deg, #49cdd0, #00bcd4) !important
}

.l-khaki {
    background: linear-gradient(45deg, khaki, #fdf181) !important
}

.l-coral {
    background: linear-gradient(45deg, #f08080, #f58787) !important
}

.l-salmon {
    background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important
}

.l-blue {
    background: linear-gradient(45deg, #72c2ff, #86f0ff) !important
}

.l-seagreen {
    background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important
}

.l-amber {
    background: linear-gradient(45deg, #fda582, #f7cf68) !important
}

.l-blush {
    background: linear-gradient(45deg, #dd5e89, #f7bb97) !important
}

.l-parpl {
    background: linear-gradient(45deg, #a890d3, #edbae7) !important
}

.l-slategray {
    background: linear-gradient(45deg, #708090, #7c8ea0) !important
}

.primary-bg {
    background: var(--primary-color)
}

.primary-text {
    color: var(--primary-color)
}

.secondary-bg {
    background: var(--secondary-color)
}

.secondary-text {
    color: var(--secondary-color)
}

.width35 {
    width: 35px
}

.media .media-body {
    color: var(--font-color);
    font-size: 14px
}

.media .media-body .media-heading {
    font-size: 16px;
    font-weight: 700;
    color: var(--heading-color)
}

.wizard,
.tabcontrol {
    width: 100%;
    overflow: hidden
}

.wizard a,
.tabcontrol a {
    outline: 0
}

.wizard ul,
.tabcontrol ul {
    list-style: none !important
}

.wizard>.steps .current-info,
.tabcontrol>.steps .current-info,
.wizard>.content>.title,
.tabcontrol>.content>.title {
    position: absolute;
    left: -999em
}

.wizard.vertical>.steps {
    float: left;
    width: 30%
}

.wizard.vertical>.steps>ul>li {
    float: none;
    width: 100%
}

.wizard.vertical>.steps>ul>li a {
    margin: 0 0 2px 0
}

.wizard.vertical>.content {
    float: left;
    margin: 0 0 0.5em 0;
    width: 70%
}

.wizard.vertical>.actions {
    float: right;
    width: 100%
}

.wizard>.steps {
    position: relative;
    width: 100%;
    margin-bottom: 2px
}

.wizard>.steps a {
    width: auto;
    margin: 0 2px 0 0;
    padding: 1em 1em;
    text-decoration: none
}

.wizard>.steps a:hover,
.wizard>.steps a:active {
    width: auto;
    margin: 0 2px 0 0;
    padding: 1em 1em;
    text-decoration: none
}

.wizard>.steps .number {
    font-size: 14px
}

.wizard>.steps .disabled a {
    background: var(--font-xlight);
    color: var(--font-ccc);
    cursor: default
}

.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
    background: var(--font-xlight);
    color: var(--font-ccc);
    cursor: default
}

.wizard>.steps .current a {
    background: var(--primary-color);
    color: var(--font-white);
    cursor: default
}

.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
    background: var(--primary-color);
    color: var(--font-white);
    cursor: default
}

.wizard>.steps .done a {
    background: var(--primary-color);
    opacity: 0.7
}

.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
    background: var(--primary-color);
    color: var(--font-white);
    opacity: 0.7
}

.wizard>.steps .error a {
    background: #de4848;
    color: var(--font-white)
}

.wizard>.steps .error a:hover,
.wizard>.steps .error a:active {
    background: #de4848;
    color: var(--font-white)
}

.wizard>.steps>ul>li {
    width: 25%;
    float: left
}

.wizard>.content {
    background: var(--white-color);
    margin: 0;
    border: 1px solid var(--border-color);
    min-height: 35em;
    overflow: hidden;
    position: relative;
    width: auto
}

.wizard>.content>.body label.error {
    margin: 0;
    font-size: 12px
}

.wizard>.actions {
    position: relative;
    width: 100%
}

.wizard>.actions>ul>li {
    float: left
}

.wizard>.actions a {
    background: var(--primary-color);
    color: var(--font-white);
    text-decoration: none;
    border-radius: .25rem;
    padding: 5px 10px
}

.wizard>.actions a:hover,
.wizard>.actions a:active {
    background: var(--primary-color);
    color: var(--font-white);
    text-decoration: none
}

.wizard>.actions .disabled a {
    background: var(--font-xlight);
    color: var(--font-ccc)
}

.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background: var(--font-xlight);
    color: var(--font-ccc)
}

.wizard .content {
    min-height: 245px;
    overflow-y: auto
}

.wizard .content .body {
    padding: 15px
}

.tabcontrol>.steps {
    position: relative;
    width: 100%
}

.tabcontrol>.steps>ul {
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1
}

.tabcontrol>.steps>ul>li {
    float: left;
    margin: 5px 2px 0 0;
    padding: 1px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.tabcontrol>.steps>ul>li:hover {
    background: var(--font-xlight);
    border: 1px solid var(--font-ccc)
}

.tabcontrol>.steps>ul>li.current {
    background: var(--white-color);
    border: 1px solid var(--font-ccc);
    border-bottom: 0 none;
    padding: 0 0 1px 0
}

.tabcontrol>.steps>ul>li.current>a {
    padding: 15px 30px 10px 30px
}

.tabcontrol>.steps>ul>li>a {
    color: var(--font-color);
    border: 0 none;
    padding: 10px 30px;
    text-decoration: none
}

.tabcontrol>.steps>ul>li>a:hover {
    text-decoration: none
}

.tabcontrol>.content {
    position: relative;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid var(--font-ccc)
}

.tabcontrol>.content>.body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%
}

.tabcontrol>.content>.body ul {
    list-style: disc !important
}

.tabcontrol>.content>.body ul>li {
    display: list-item
}

@media only screen and (max-width: 767px) {
    .wizard>.steps>ul>li {
        width: 100%
    }

    .wizard.wizard.vertical>.content,
    .wizard.wizard.vertical>.steps {
        width: 100%
    }
}

.table {
    color: var(--font-color)
}

.table .thead-primary th {
    color: var(--font-white);
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.table .thead-success th {
    color: var(--font-white);
    background-color: #22af46;
    border-color: #22af46
}

.table .thead-danger th {
    color: var(--font-white);
    background-color: #de4848;
    border-color: #de4848
}

.table .thead-info th {
    color: var(--font-white);
    background-color: #3C89DA;
    border-color: #3C89DA
}

.table .thead-purple th {
    color: var(--font-white);
    background-color: #7c48d4;
    border-color: #7c48d4
}

.table tbody tr td,
.table tbody th td {
    vertical-align: middle;
    white-space: nowrap
}

.table tbody tr:hover {
    color: var(--heading-color)
}

.table td,
.table th {
    border-color: var(--border-color)
}

.table thead th {
    border-bottom-color: var(--primary-color);
    border-top-color: var(--border-color)
}

.page-loader-wrapper {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--primary-color)
}

.page-loader-wrapper p {
    color: var(--font-white)
}

.page-loader-wrapper .loader {
    position: relative;
    top: calc(40% - 30px)
}

.number-chart {
    overflow: hidden
}

.number-chart h4 {
    font-weight: 600
}

.number-chart canvas {
    width: 100% !important
}

.chat-widget {
    height: 250px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.chat-widget::-webkit-scrollbar {
    width: 5px;
    background: var(--text-muted)
}

.chat-widget li {
    list-style: none;
    width: 100%
}

.chat-widget li.left img {
    float: left
}

.chat-widget li.left .chat-info {
    float: left;
    background: var(--font-xlight)
}

.chat-widget li.left .chat-info:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--font-xlight);
    left: -10px;
    top: 12px
}

.chat-widget li.left .chat-info span {
    color: var(--bg-hc-color)
}

.chat-widget li.right img {
    float: right
}

.chat-widget li.right .chat-info:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: -10px;
    top: 12px
}

.chat-widget li .chat-info {
    width: calc(100% - 60px);
    border-radius: 8px;
    padding: 20px;
    position: relative
}

.chat-widget li .chat-info:before {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 12px
}

.chat-widget li .datetime {
    font-size: 12px;
    color: var(--text-muted)
}

.chat-widget li img {
    height: 40px;
    width: 40px
}

.nav-pills>li>a {
    background-color: var(--bg-hc-color)
}

.info-box-2 {
    display: flex;
    cursor: default;
    position: relative;
    overflow: hidden
}

.info-box-2 .icon i {
    font-size: 30px;
    line-height: 70px
}

.info-box-2 .chart {
    position: relative;
    width: 100px
}

.info-box-2 .chart.chart-bar {
    height: 100%
}

.info-box-2 .chart.chart-bar canvas {
    vertical-align: baseline !important
}

.info-box-2 .chart.chart-pie {
    height: 100%
}

.info-box-2 .chart.chart-pie canvas {
    vertical-align: baseline !important
}

.info-box-2 .chart.chart-line {
    height: 100%
}

.info-box-2 .chart.chart-line canvas {
    vertical-align: baseline !important
}

.info-box-2 .content {
    padding: 7px 0px
}

.info-box-2 .content .text {
    margin-top: 11px;
    color: var(--font-color)
}

.info-box-2 .content .number {
    font-weight: 400;
    font-size: 22px;
    color: var(--font-color)
}

.gender-overview .fa-male {
    color: #00bdfb
}

.gender-overview .fa-female {
    color: #ff68b3
}

.weather2 .city-selected {
    position: relative;
    overflow: hidden;
    border-radius: 3px 3px 0 0
}

.weather2 .city-selected .city {
    font-size: 24px
}

.weather2 .city-selected .city span {
    font-size: 13px;
    text-transform: lowercase
}

.weather2 .city-selected .night {
    font-size: 15px;
    text-transform: uppercase
}

.weather2 .city-selected .temp h2 {
    font-size: 73px;
    position: relative
}

.weather2 .city-selected .icon i {
    font-size: 70px
}

.weather2 .table tbody tr td {
    padding: 14px 20px
}

.weather2 .days-list .day {
    padding: 20px 10px
}

.weather2 .days-list .day i {
    font-size: 30px
}

.weather2 .carousel .carousel-inner {
    box-shadow: none
}

.weather .city {
    position: relative
}

.weather .city i {
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 0
}

.weather .days li {
    width: 13.20%
}

.weather .days li img {
    width: 25px
}

.weather .days li h5 {
    font-size: 11px
}

.weather3 {
    overflow: hidden
}

.weather3 .top::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5)
}

.weather3 .top .wrapper {
    padding: 30px;
    position: relative;
    z-index: 1;
    color: var(--font-white)
}

.weather3 .top .wrapper .mynav {
    height: 20px
}

.weather3 .top .wrapper .heading {
    font-size: 35px;
    font-weight: 400
}

.weather3 .top .wrapper .location {
    font-size: 21px;
    font-weight: 400
}

.weather3 .top .wrapper .temp .temp-value {
    font-size: 85px;
    font-weight: 600
}

.weather3 .top .wrapper .temp .deg {
    font-size: 35px;
    font-weight: 600;
    vertical-align: top
}

.weather3 .top .wrapper .temp .temp-type {
    font-size: 85px;
    color: var(--font-white)
}

.weather3 .bottom {
    padding: 20px;
    background: var(--white-color)
}

.weather3 .bottom .forecast {
    overflow: hidden;
    font-size: 0;
    max-height: 155px
}

.weather3 .bottom .forecast li {
    font-size: 18px;
    color: var(--font-ccc);
    line-height: 2em
}

.weather3 .bottom .forecast li.active {
    color: var(--font-color)
}

.weather3 .bottom .forecast li .condition {
    float: right;
    line-height: 2em
}

.weather4 i {
    font-size: 35px
}

.weather5 {
    overflow: hidden
}

.weather5 .widget {
    display: flex;
    flex-wrap: wrap
}

.weather5 .weatherIcon {
    flex: 1 100%;
    height: 60%;
    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 80px;
    padding: 20px 0
}

.weather5 .weatherIcon i {
    padding-top: 20px
}

.weather5 .weatherInfo {
    flex: 0 0 70%;
    height: 40%;
    background: var(--secondary-color);
    display: flex;
    align-items: center;
    color: var(--font-white)
}

.weather5 .weatherInfo .temperature {
    flex: 0 0 40%;
    width: 100%;
    font-size: 65px;
    display: flex;
    justify-content: space-around
}

.weather5 .weatherInfo .description {
    flex: 0 60%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center
}

.weather5 .date {
    flex: 0 0 30%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--font-white);
    font-size: 28px;
    line-height: 97px
}

.weather6 {
    text-align: center
}

.weather6 .temp {
    font-size: 30px
}

.top_widget .icon {
    width: 50px;
    height: 50px;
    background: var(--font-xlight);
    border-radius: 50px;
    position: absolute;
    top: 20px;
    right: 20px
}

.top_widget .icon i {
    font-size: 35px;
    line-height: 30px;
    color: var(--bg-hc-color);
    padding-right: 5px;
}

.top_counter {
    display: flex
}

.top_counter>.body {
    display: flex
}

.top_counter .icon {
    width: 50px;
    height: 50px;
    background: var(--font-xlight);
    border-radius: 50px;
    margin-right: 15px
}

.top_counter .icon i {
    font-size: 22px;
    line-height: 50px
}

.stats-report .stat-item h5 {
    font-size: 13px
}

.basic-list li span {
    float: right
}

.new_timeline .header {
    background: url(http://via.placeholder.com/275x120);
    background-size: cover;
    color: var(--font-white);
    position: relative
}

.new_timeline .header .color-overlay {
    padding: 2em;
    box-sizing: border-box;
    background: rgba(123, 94, 155, 0.5);
    line-height: normal
}

.new_timeline .header .color-overlay .day-number {
    font-size: 4em
}

.new_timeline .header .color-overlay .day-name {
    font-size: 23px
}

.new_timeline .header .color-overlay .month {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 2px
}

.new_timeline>ul {
    padding: 1em 0 0 2em;
    list-style: none;
    position: relative
}

.new_timeline>ul::before {
    content: ' ';
    height: 100%;
    width: 1px;
    background-color: var(--font-xlight);
    position: absolute;
    top: 0;
    left: 2.5em;
    z-index: 1
}

.new_timeline li div {
    margin: 1em 0;
    vertical-align: top
}

.new_timeline .bullet {
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: var(--white-color);
    z-index: 2;
    position: relative
}

.new_timeline .bullet.pink {
    border: 2px solid #e0455a
}

.new_timeline .bullet.green {
    border: 2px solid #42d182
}

.new_timeline .bullet.orange {
    border: 2px solid #ff812d
}

.new_timeline .time {
    width: 20%;
    font-size: 0.75em;
    padding-top: 0.25em
}

.new_timeline .desc {
    width: 65%
}

.new_timeline h3 {
    font-size: 16px;
    font-weight: 400
}

.new_timeline h4 {
    font-size: 13px;
    font-weight: 400;
    color: var(--font-color)
}

.new_timeline .people img {
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.team-info li {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.team-info li+li {
    margin-left: -10px
}

.team-info li img {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    width: 30px;
    border: 2px solid var(--card-color);
    box-shadow: 0px 2px 5px 0px var(--box-shadow)
}

.team-info li:hover {
    -webkit-transform: translateY(-4px) scale(1.02);
    -moz-transform: translateY(-4px) scale(1.02);
    -ms-transform: translateY(-4px) scale(1.02);
    -o-transform: translateY(-4px) scale(1.02);
    transform: translateY(-4px) scale(1.02);
    z-index: 999
}

.team-info li:hover img {
    -webkit-box-shadow: 0 10px 20px var(--box-shadow);
    box-shadow: 0 10px 20px var(--box-shadow)
}

.list-widget .badge {
    float: right
}

.list-widget .list-group-item {
    border-left: 0;
    border-right: 0;
    padding: 20px 20px;
    border-color: var(--border-color);
    background: var(--card-color)
}

.list-widget .list-group-item:first-child {
    border-top: 0
}

.list-widget .list-group-item:last-child {
    border-bottom: 0
}

.list-widget .list-group-item i {
    width: 30px;
    font-size: 18px
}

.chart.easy-pie-chart-1 {
    position: relative;
    width: 100px;
    height: 100px
}

.chart.easy-pie-chart-1>span>img {
    left: 0;
    margin-top: -2px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 60%;
    height: 60%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto
}

.c_list tr td {
    vertical-align: middle
}

.c_list .c_name {
    font-weight: 600
}

.c_list .avatar {
    width: 34px;
    box-shadow: 0px 2px 5px 0px var(--box-shadow);
    border: 1px solid var(--white-color)
}

.c_list address i {
    width: 25px;
    font-size: 15px
}

.team_list .dd-handle {
    background: var(--font-xlight)
}

.taskboard .planned_task .dd-handle {
    background: var(--font-xlight);
    color: var(--bg-hc-color)
}

.taskboard .progress_task .dd-handle {
    background: #ff812d;
    color: var(--font-white)
}

.taskboard .completed_task .dd-handle {
    background: #42d182;
    color: var(--font-white)
}

.taskboard .dd:hover {
    cursor: move
}

.taskboard .dd-handle {
    border-color: var(--border-color)
}

.taskboard .dd-content {
    background: var(--card-color);
    border-color: var(--border-color)
}

.b4gallery .gallery-item:hover {
    cursor: pointer
}

.right_chat li {
    position: relative
}

.right_chat li.offline .status {
    background: #ff812d
}

.right_chat li.online .status {
    background: #42d182
}

.right_chat li.me .status {
    background: #0c85e0
}

.right_chat .media .media-object {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    width: 40px;
    border: 2px solid transparent
}

.right_chat .media .name {
    color: var(--font-color);
    margin: 0px;
    font-size: 15px
}

.right_chat .media .message {
    font-size: 13px;
    color: var(--font-color)
}

.right_chat .media .status {
    position: absolute;
    left: 23px;
    top: 30px;
    width: 11px;
    height: 11px;
    min-width: inherit;
    border: 2px solid var(--white-color);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px
}

.table-custom.table {
    border-collapse: collapse !important
}

.table-custom.table thead th {
    border-top: 0
}

.table-custom.table tfoot th {
    border-bottom: 0
}

.table-custom.table tbody tr:hover {
    background-color: var(--font-xlight)
}

.dt-bootstrap4 .dt-buttons,
.dt-bootstrap4 .dataTables_info {
    margin-bottom: 10px
}

.dt-bootstrap4 .dt-buttons+.dataTables_filter,
.dt-bootstrap4 .dt-buttons+.dataTables_paginate,
.dt-bootstrap4 .dataTables_info+.dataTables_filter,
.dt-bootstrap4 .dataTables_info+.dataTables_paginate {
    float: right
}

.social_media_table .table tbody tr td {
    vertical-align: middle
}

.social_media_table .list-name {
    text-transform: uppercase
}

.social_icon {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    color: var(--font-white);
    line-height: 40px;
    width: 40px;
    height: 40px
}

.social_icon.linkedin {
    background: #007bb5
}

.social_icon.twitter-table {
    background: #55acee
}

.social_icon.facebook {
    background: #007bb5
}

.social_icon.google {
    background: #dd4b39
}

.social_icon.youtube {
    background: #bb0000
}

.profile_state i {
    font-size: 25px
}

.maintenance {
    background: var(--card-color);
    color: var(--font-color)
}

.testimonials .body {
    padding: 40px
}

.testimonials .body i {
    font-size: 50px;
    color: var(--font-xlight)
}

.testimonials .body p {
    font-size: 15px
}

.testimonial2 .testimonial-section {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    height: auto;
    padding: 15px;
    position: relative;
    border: 1px solid var(--white-color)
}

.testimonial2 .testimonial-section::after {
    top: 100%;
    left: 50px;
    border: solid transparent;
    content: " ";
    position: absolute;
    border-top-color: var(--white-color);
    border-width: 15px;
    margin-left: -15px
}

.testimonial2 .testimonial-desc {
    margin-top: 20px;
    text-align: left;
    padding-left: 15px
}

.testimonial2 .testimonial-desc img {
    width: 70px
}

.testimonial2 .testimonial-desc .testimonial-writer {
    display: inline-block;
    vertical-align: top;
    padding-left: 10px
}

.testimonial2.default .testimonial-section {
    border-color: var(--font-color)
}

.testimonial2.default .testimonial-section::after {
    border-top-color: var(--font-color)
}

.testimonial2.primary .testimonial-section {
    border-color: var(--primary-color)
}

.testimonial2.primary .testimonial-section::after {
    border-top-color: var(--primary-color)
}

.testimonial3 .testimonial-section {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    height: auto;
    padding: 15px;
    position: relative;
    border: 1px solid var(--white-color)
}

.testimonial3 .testimonial-section::after {
    top: 100%;
    right: 50px;
    border: solid transparent;
    content: " ";
    position: absolute;
    border-top-color: var(--white-color);
    border-width: 15px;
    margin-right: -15px
}

.testimonial3 .testimonial-desc {
    margin-top: 20px;
    text-align: right;
    padding-right: 15px
}

.testimonial3 .testimonial-desc img {
    width: 70px;
    float: right
}

.testimonial3 .testimonial-desc .testimonial-writer {
    display: inline-block;
    vertical-align: top;
    padding-right: 10px
}

.testimonial3.default .testimonial-section {
    border-color: var(--font-color)
}

.testimonial3.default .testimonial-section::after {
    border-top-color: var(--font-color)
}

.testimonial3.primary .testimonial-section {
    border-color: var(--primary-color)
}

.testimonial3.primary .testimonial-section::after {
    border-top-color: var(--primary-color)
}

.testimonial3.warning .testimonial-section {
    border-color: #f3ad06
}

.testimonial3.warning .testimonial-section::after {
    border-top-color: #f3ad06
}

.testimonial3.danger .testimonial-section {
    border-color: #de4848
}

.testimonial3.danger .testimonial-section::after {
    border-top-color: #de4848
}

.testimonial4 blockquote {
    background: var(--font-xlight) none repeat scroll 0 0;
    border: medium none;
    color: var(--bg-hc-color);
    line-height: 20px;
    padding: 15px;
    position: relative
}

.testimonial4 blockquote:before {
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    content: " ";
    position: absolute;
    border-bottom: 20px solid var(--white-color);
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-left-style: inset;
    border-bottom-style: inset
}

.testimonial4 blockquote:after {
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 20px 20px 0 0
}

.testimonial4 blockquote.danger:after {
    border-color: #de4848 transparent transparent transparent
}

.testimonial4 blockquote.warning:after {
    border-color: #f3ad06 transparent transparent transparent
}

.testimonial4 blockquote.info:after {
    border-color: #3C89DA transparent transparent transparent
}

.testimonial4 blockquote.primary:after {
    border-color: var(--primary-color) transparent transparent transparent
}

.testimonial4 .carousel-info {
    overflow: hidden
}

.accordion.custom .card-header {
    background: transparent;
    border-color: var(--font-xlight)
}

.accordion.custom .card-header .btn {
    color: var(--font-color)
}

.accordion.custom .card-header .btn:hover,
.accordion.custom .card-header .btn:focus {
    text-decoration: none
}

.accordion.custom .card-body {
    font-size: 15px;
    line-height: 28px;
    color: var(--font-color)
}

.morris-hover.morris-default-style {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.flot-chart {
    width: 100%;
    height: 320px
}

.panel-switch-btn {
    position: relative;
    right: 20px;
    z-index: 9
}

.panel-switch-btn label {
    font-weight: 700 !important
}

.legendLabel {
    width: 85px !important;
    position: relative;
    left: 3px
}

#multiple_axis_chart .legendLabel {
    width: 160px !important
}

.sparkline {
    text-align: center
}

.dashboard-donut-chart {
    width: 280px;
    margin: 0 auto
}

.member-card .header {
    min-height: 150px
}

.member-card .member-img {
    position: relative;
    margin-top: -70px
}

.member-card .member-img img {
    width: 150px;
    border: 3px solid var(--white-color);
    box-shadow: 0px 10px 25px 0px var(--box-shadow)
}

.member-card .social-links li a {
    padding: 5px 10px
}

.w_social2 .pw_img {
    position: relative;
    overflow: hidden
}

.w_social2 .pw_img:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg)
}

.w_social2 .pw_img:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s
}

@-webkit-keyframes shine {
    100% {
        left: 125%
    }
}

@keyframes shine {
    100% {
        left: 125%
    }
}

.w_social2 .pw_img img {
    border-radius: 3px 3px 0 0
}

.w_social2 .pw_content .pw_header {
    padding: 20px;
    border-bottom: 1px solid var(--border-color)
}

.w_social2 .pw_content .pw_header small {
    font-size: 12px
}

.w_social2 .pw_content .pw_meta {
    padding: 20px
}

.w_social2 .pw_content .pw_meta span {
    font-weight: 500
}

.w_user img {
    height: 72px;
    float: left
}

.w_user .wid-u-info {
    margin-left: 90px
}

.w_feed {
    border-radius: .55rem;
    color: var(--font-white)
}

.w_feed.facebook {
    background: #3b5998
}

.w_feed.twitter {
    background: #00aced
}

.w_feed.google {
    background: #d34836
}

.w_feed .carousel-inner {
    box-shadow: none
}

.w_feed .carousel-inner .carousel-item {
    padding: 20px
}

.w_feed .carousel-inner h4 {
    font-weight: 300
}

.w_feed .carousel-inner h4 span {
    font-weight: 700
}

.w_followers a:hover .btn {
    opacity: 1
}

.w_followers .btn {
    position: absolute;
    right: 0;
    top: 4px;
    opacity: 0
}

.w_social3 .card {
    padding: 25px
}

.w_social3 .card .fa {
    font-size: 30px
}

.w_social3 .text {
    margin-top: 0 !important
}

.w_social3 .facebook-widget .icon i {
    color: #3b5998
}

.w_social3 .google-widget .icon i {
    color: #d34836
}

.w_social3 .twitter-widget .icon i {
    color: #0084b4
}

.w_social3 .instagram-widget .icon i {
    color: #fb3958
}

.w_social3 .linkedin-widget .icon i {
    color: #0077B5
}

.w_social3 .behance-widget .icon i {
    color: #053eff
}

.w_profile {
    position: relative
}

.w_profile .profile-image img {
    border-radius: 50%;
    width: 180px;
    border: 3px solid var(--white-color);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.w_profile .social-icon a {
    margin: 0 5px
}

@media screen and (max-width: 640px) {
    .w_profile {
        text-align: center
    }

    .w_profile .profile-image {
        margin-bottom: 20px
    }
}

.ms-container {
    width: auto !important
}

.todo_list .fancy-checkbox input[type="checkbox"]:checked+span {
    text-decoration: line-through
}

.w_bitcoin .body {
    position: absolute
}

.w_bitcoin #sparkline16 {
    margin-bottom: -2px
}

.feeds_widget li {
    padding: 12px 15px;
    border: 1px solid var(--border-color);
    margin: 4px 0
}

.feeds_widget li:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14), 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer
}

.feeds_widget li .feeds-left {
    float: left;
    width: 33px;
    font-size: 18px
}

.feeds_widget li .feeds-body {
    width: auto
}

.feeds_widget li .feeds-body .title {
    font-size: 16px
}

.feeds_widget li .feeds-body>small {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    font-size: 13px;
    color: var(--font-color)
}

.note-editor .btn {
    font-size: 12px;
    padding: 5px 10px
}

.profilepage_1 .profile-heading {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: normal
}

.profilepage_1 .user-photo {
    width: 90px;
    border: 2px solid var(--border-color)
}

.profilepage_1 .profile-section {
    margin-bottom: 70px
}

.plan {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 15px 30px;
    margin-bottom: 30px;
    background-color: var(--font-xlight);
    border: 1px solid var(--border-color);
    color: var(--font-color)
}

.plan .plan-title {
    margin: 0 0 15px 0
}

.plan .plan-title span {
    font-size: 15px;
    color: var(--text-muted)
}

.plan .plan-title span i {
    margin-left: 10px;
    color: #22af46
}

.plan.selected-plan {
    background-color: #F0FFDF;
    border: 1px solid var(--border-color)
}

.plan .list-plan-details {
    margin-bottom: 20px
}

.plan .list-plan-details li {
    margin-bottom: 5px
}

.payment-info .payment-name {
    margin-bottom: 12px;
    font-size: 20px
}

.payment-info .edit-payment-info {
    float: right;
    position: relative;
    top: -15px
}

.billing-history .billing-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px
}

.billing-history .billing-title span {
    margin-left: 10px;
    font-size: 15px
}

.billing-history .amount {
    font-size: 18px
}

.billing-history .action {
    text-align: right
}

.login-session {
    padding: 30px 0;
    position: relative;
    border-bottom: 1px solid var(--border-color)
}

.login-session .device-icon {
    font-size: 25px;
    float: left
}

.login-session .login-info {
    padding-left: 40px
}

.login-session .login-info .login-title {
    margin: 0 0 5px 0;
    font-size: 16px
}

.login-session .login-info .login-detail {
    color: var(--text-muted)
}

.login-session .btn-logout {
    position: absolute;
    right: 0;
    top: 50%;
    padding: 0;
    margin-top: -11px
}

ul.list-login-session>li:last-child .login-session {
    border-bottom: none
}

ul.list-email-received>li {
    margin-bottom: 10px
}

.connected-app {
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid var(--border-color)
}

.connected-app .app-icon {
    font-size: 22px;
    float: left
}

.connected-app .connection-info {
    padding-left: 40px
}

.connected-app .connection-info .app-title {
    margin: 0 0 5px 0;
    font-size: 18px
}

.connected-app .connection-info .actions a {
    margin-right: 15px
}

ul.list-connected-app>li:first-child .connected-app {
    padding-top: 0
}

ul.list-connected-app>li:last-child .connected-app {
    border-bottom: none
}

.timeline-item {
    padding: 3em 2em 2em;
    position: relative;
    border-left-width: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-color: var(--font-xlight)
}

.timeline-item:before {
    content: attr(date-is);
    position: absolute;
    left: 2em;
    top: 1em;
    color: var(--font-color)
}

.timeline-item:after {
    width: 15px;
    height: 15px;
    top: 1em;
    position: absolute;
    left: -8px;
    border-radius: 10px;
    content: '';
    background: var(--white-color);
    border: 3px solid
}

.timeline-item.green:after {
    border-color: #42d182
}

.timeline-item.blue:after {
    border-color: #0c85e0
}

.timeline-item.warning:after {
    border-color: #ff812d
}

.timeline-item.red:after {
    border-color: #fe2053
}

.timeline-item.dark:after {
    border-color: var(--dark-color)
}

.timeline-item .msg {
    margin: 15px 0
}

.page-calendar .event-name h6 {
    text-transform: capitalize
}

.page-calendar .event-name h4 {
    font-size: 25px;
    line-height: 24px
}

.page-calendar .event-name h4 span {
    font-size: 11px;
    line-height: 16px
}

.page-calendar .event-name p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%
}

.page-calendar .event-name address {
    font-size: 11px
}

.page-calendar .event-name address i {
    font-size: 12px
}

.event-control {
    background-color: var(--font-xlight);
    font-size: 13px;
    cursor: pointer
}

.event-control:hover {
    background-color: var(--font-white)
}

.event-control a {
    color: var(--font-color);
    cursor: pointer;
    opacity: .5
}

.event-control a:hover {
    opacity: 1
}

.event-control[class*='bg-']:not(.bg-default):not(.bg-white) a {
    color: var(--font-color) !important;
    opacity: 1
}

.event-control[class*='bg-']:not(.bg-default):not(.bg-white) a:hover {
    color: var(--font-white) !important
}

.fc-overlay {
    display: none;
    position: absolute;
    min-width: 260px;
    padding: 15px 0;
    top: auto;
    left: 50%;
    margin-left: -150px;
    z-index: 1000;
    color: var(--font-color) !important
}

.fc-overlay.left {
    left: 100%;
    top: -15px;
    padding: 0 10px;
    margin: 0
}

.fc-overlay.right {
    left: auto;
    right: 100%;
    top: -15px;
    padding: 0 10px
}

.fc-overlay .panel {
    padding: 10px
}

.fc .fc-toolbar {
    color: var(--font-color);
    background: transparent;
    border-radius: 0
}

.fc .fc-toolbar h2 {
    text-transform: uppercase;
    font-size: 16px
}

.fc .fc-toolbar .fc-today-button,
.fc .fc-toolbar .fc-state-default {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    font-size: 14px;
    padding: 8px 18px;
    height: auto;
    border: none;
    box-shadow: none;
    text-shadow: none
}

.fc .fc-view-container .fc-event {
    padding: 3px 5px;
    font-size: 12px;
    border-radius: .1875rem;
    border: 0;
    color: var(--font-xlight)
}

.fc .fc-view-container .fc-event.fc-event-end:not(.fc-event-start) {
    border: 0 !important
}

.fc .fc-view-container .fc-event:focus,
.fc .fc-view-container .fc-event:hover,
.fc .fc-view-container .fc-event:active {
    z-index: 1000;
    color: var(--font-white)
}

.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
    border: 1px solid var(--font-xlight);
    padding: 10px;
    color: var(--font-color)
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content {
    border: 0;
    border-bottom: 1px solid var(--font-xlight);
    border-right: 1px solid var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content.fc-state-highlight {
    background: var(--white-color)
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number {
    padding: 5px 10px;
    margin: 5px;
    color: var(--font-color);
    font-size: 15px
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number.fc-state-highlight {
    background-color: #fe2053;
    color: var(--font-white);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 4px 6px;
    float: right
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td:first-child.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td:first-child.fc-widget-content {
    border-left: 1px solid var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row .fc-bg {
    border-bottom: 1px solid var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row:last-of-type .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row:last-of-type .fc-bg {
    border-bottom: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter {
    border-left: 1px solid var(--font-xlight);
    border-right: 1px solid var(--font-xlight);
    background-color: var(--font-xlight);
    color: var(--font-color);
    font-size: 12px;
    font-weight: 100;
    padding: 10px 5px
}

.fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-state-highlight {
    background: var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-sat,
.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-sat {
    border-right: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr {
    line-height: 35px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr th,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
    border: 0;
    border-left: 1px solid var(--font-xlight);
    border-right: 1px solid var(--font-xlight);
    background-color: var(--font-xlight);
    color: var(--font-color);
    font-size: 12px;
    font-weight: 100;
    padding-top: 10px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content {
    border-bottom: 1px solid var(--font-xlight);
    line-height: 50px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content:last-child,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content:last-child {
    border-right: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr.fc-minor td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr.fc-minor td.fc-widget-content {
    border-bottom: 1px solid var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider {
    border: 1px solid var(--font-xlight)
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider .fc-agenda-divider-inner,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider .fc-agenda-divider-inner {
    background-color: var(--font-xlight);
    height: 3px
}

.fc .fc-view-container .fc-list-view .fc-list-item {
    color: var(--font-color)
}

.fc .fc-view-container .fc-other-month {
    background: var(--font-xlight)
}

.fc .fc-view-container .fc-basic-view .fc-day-number,
.fc .fc-view-container .fc-basic-view .fc-week-number {
    float: left;
    padding: 10px 0 5px 10px
}

.fc .fc-popover {
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    border-radius: .55rem
}

.fc .fc-popover .fc-event-container {
    padding: 0
}

.fc .fc-popover .fc-event {
    padding: 4px 8px;
    margin: 2px 0px
}

.fc .fc-agendaWeek-view .fc-head .fc-widget-header span,
.fc .fc-agendaDay-view .fc-head .fc-widget-header span,
.fc .fc-listWeek-view .fc-head .fc-widget-header span {
    padding: 10px;
    display: inline-block
}

.fc .fc-agendaWeek-view .fc-unthemed td,
.fc .fc-agendaDay-view .fc-unthemed td,
.fc .fc-listWeek-view .fc-unthemed td {
    border-color: #f4f7f6
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: var(--border-color)
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
    background: var(--card-color)
}

@media only screen and (max-width: 420px) {
    .fc-toolbar .fc-right {
        display: none
    }

    .fc-toolbar .fc-left {
        width: 100%;
        margin-bottom: 15px
    }

    .fc .fc-toolbar .fc-today-button {
        float: right
    }
}

.file_manager .file {
    position: relative;
    border-radius: .1875rem;
    overflow: hidden
}

.file_manager .file .image,
.file_manager .file .icon {
    max-height: 180px;
    overflow: hidden;
    background-size: cover;
    background-position: top
}

.file_manager .file .hover {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 10px;
    top: 10px;
    display: none
}

.file_manager .file a:hover .hover {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.file_manager .file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%
}

.file_manager .file .icon i {
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
    color: var(--font-color);
    line-height: 100px
}

.file_manager .file .file-name {
    padding: 10px;
    border-top: 1px solid var(--border-color)
}

.file_manager .file .file-name small .date {
    float: right
}

.folder {
    padding: 20px;
    display: block;
    color: var(--font-color)
}

@media only screen and (max-width: 992px) {
    .file_manager .nav-tabs {
        padding-left: 0;
        padding-right: 0
    }

    .file_manager .nav-tabs .nav-item {
        display: inline-block
    }
}

.pricing {
    color: var(--font-color)
}

.pricing li {
    list-style: none;
    padding: 13px
}

.pricing li:first-child,
.pricing li:last-child {
    padding: 20px 13px
}

.pricing li:nth-last-child(2) {
    padding: 30px 13px
}

.pricing li+li {
    border-top: 1px solid var(--border-color)
}

.pricing big {
    font-size: 32px
}

.pricing h3 {
    font-size: 36px
}

.pricing span {
    color: var(--font-ccc);
    font-weight: 400
}

.pricing.active big,
.pricing.active h3 {
    color: #0c85e0
}

.pricing2 .pricing-plan {
    border-bottom: none;
    flex-basis: 100%;
    padding: 25px 0
}

.pricing2 .pricing-plan .pricing-img {
    max-width: 100%
}

.pricing2 .pricing-plan .pricing-header {
    color: var(--font-color);
    font-weight: 600;
    letter-spacing: 1px
}

.pricing2 .pricing-plan .pricing-features {
    letter-spacing: 1px;
    margin: 50px 0 25px;
    line-height: 30px;
    list-style: none
}

.pricing2 .pricing-plan .pricing-price {
    display: block;
    font-size: 32px;
    font-weight: 700
}

.pricing3 .pricing-option {
    padding: 20px
}

.pricing3 .pricing-option i {
    font-size: 60px
}

.pricing3 .pricing-option .price {
    font-size: 2rem;
    text-transform: uppercase;
    position: relative
}

.pricing3 .pricing-option .price b {
    position: absolute;
    font-size: 1rem;
    font-weight: 600
}

.invoice1 .logo,
.invoice1 .clientlogo {
    float: left;
    height: 70px;
    width: 70px
}

.invoice1 .info {
    float: left
}

.invoice1 .invoice-top .title {
    float: right
}

.invoice1 .invoice-mid .info {
    width: calc(100% - 100px)
}

.invoice .table tr td {
    vertical-align: middle
}

.appliances-grp .body {
    clear: both;
    overflow: hidden
}

.appliances-grp .icon {
    float: left;
    width: 40px;
    margin-right: 15px
}

.appliances-grp .icon img {
    max-width: 100%
}

.appliances-grp .content {
    float: right;
    width: calc(100% - 60px)
}

.appliances-grp .content h6 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600
}

.appliances-grp .content h6 span {
    float: right;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400
}

.appliances-grp .content p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0
}

.appliances-grp .content p span {
    float: right
}

.property.grid {
    display: flex;
    flex-direction: column
}

.property.grid .thumb {
    position: relative;
    margin-bottom: 30px
}

.property.grid .thumb .icon {
    position: absolute;
    top: 20px;
    right: 20px
}

.property.grid .thumb .icon a {
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.7)
}

.property .tag li a {
    background: var(--primary-color3);
    color: var(--font-white);
    border-radius: .1875rem;
    padding: 5px 10px
}

.property .tag li+li a {
    background: var(--secondary-color2)
}

.property .property-info {
    border-radius: .1875rem;
    background: var(--font-xlight);
    padding: 8px 15px
}

.property .property-info a {
    color: var(--font-color)
}

.amenities-list li {
    line-height: 30px;
    font-size: 15px
}

.w60 {
    width: 60px
}

.w100 {
    width: 100px
}